import { theme } from "../../../../styles/theme";
import Box from "@mui/material/Box";
import { AddCustomerButton } from "../../customer/add/addCustomerButton.component";
import { ListCustomerView } from "../../customer/list/listCustomerView.component";
import { Stack } from "@mui/material";

export const LeftPanel = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "20%",
          padding: 1,
          paddingBottom: 0,
          margin: 0,
          backgroundColor: theme.background.componentLight,
        }}
      >
        <Stack direction="column" spacing={2}>
          <AddCustomerButton />
          <ListCustomerView />
        </Stack>
      </Box>
    </>
  );
};
