// api/BaseApiClient.ts
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from "axios";
import { msalInstance } from "../../auth";
import { appConfig } from "../../config/configLoader";

export class BaseService {
  protected apiClient: AxiosInstance;

  constructor() {
    this.apiClient = axios.create({
      baseURL: appConfig.BASE_URL,
    });

    // Add request interceptor to add the token
    this.apiClient.interceptors.request.use(
      async (config) => {
        const response = await msalInstance.acquireTokenSilent({
          scopes: ["openid", "profile", "email"],
        });
        const accessToken = response.accessToken;
        config.headers.Authorization = `Bearer ${accessToken}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Add response interceptor for error handling
    this.apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        // Handle errors

        console.error("API Error: ", error);
        return Promise.reject(error);
      }
    );
  }

  private mergeHeaders(
    customHeaders?: RawAxiosRequestHeaders
  ): Record<string, string> {
    const defaultHeaders: Record<string, string> = {
      "Content-Type": "application/json",
    };

    const headers: Record<string, string> = { ...defaultHeaders };

    if (customHeaders) {
      Object.keys(customHeaders).forEach((key) => {
        headers[key] = customHeaders[key] as string;
      });
    }

    return headers;
  }

  protected async get<T>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const mergedConfig = {
      ...config,
      headers: this.mergeHeaders(config?.headers as RawAxiosRequestHeaders),
    };
    return this.apiClient.get<T>(path, mergedConfig);
  }

  protected async post<T>(
    path: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const mergedConfig = {
      ...config,
      headers: this.mergeHeaders(config?.headers as RawAxiosRequestHeaders),
    };
    return this.apiClient.post<T>(path, data, mergedConfig);
  }

  protected async put<T>(
    path: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const mergedConfig = {
      ...config,
      headers: this.mergeHeaders(config?.headers as RawAxiosRequestHeaders),
    };
    return this.apiClient.put<T>(path, data, mergedConfig);
  }

  protected async delete<T>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const mergedConfig = {
      ...config,
      headers: this.mergeHeaders(config?.headers as RawAxiosRequestHeaders),
    };
    return this.apiClient.delete<T>(path, mergedConfig);
  }

  protected isResponseSuccess(response: AxiosResponse): boolean {
    return response.status >= 200 && response.status < 300;
  }
}
