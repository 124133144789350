import { AppContext } from "../../context/app/app.context.model";
import { useAppContext } from "../../context/app/app.context";

export const ContextWrapper = (props: {
  children: JSX.Element;
}): JSX.Element => {
  const context = useAppContext();
  return (
    <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
  );
};
