// Purpose: Constants for the port service.
export const path = {
  TENANT_ADAPTERS_CUSTOM_PORTS: "/tenantadapters/tenants/customports/",
  ONBOARDING_MIDDLEWARE_GET_PORT_FUNCTIONS: "/onboardingmiddleware/GetPortFunctions",
  ONBOARDING_MIDDLEWARE_GET_PORT_STATUSES: "/onboardingmiddleware/GetPortStatuses",
  ONBOARDING_MIDDLEWARE_GET_PORT_COUNTRIES: "/onboardingmiddleware/GetPortCountries",
  ONBOARDING_MIDDLEWARE_GET_PORT_LOCODES: "/onboardingmiddleware/GetPortLocodes",
  ONBOARDING_MIDDLEWARE_SEARCH_UNECE_PORTS: "/onboardingmiddleware/SearchPorts",
  ONBOARDING_MIDDLEWARE_GET_CUSTOM_PORT: "/onboardingmiddleware/GetCustomPort",
};

export const headerKey = {
  CC_ACTIVE_CLIENT: "cc-active-client",
  CC_TENANT_ID: "cc-tenantid",
};

export const paging = {
  MAX_PAGE_SIZE: 100,
};