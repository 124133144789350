import {
  AccountInfo,
  Configuration,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { getMsalConfig } from './msalConfig';
import { SessionUtility } from "../utils/data/session.utility";
import { UserUtility } from "../utils/data/user.utility";

const defaultMsalConfig: Configuration = {
  auth: {
    clientId: "",  
    authority: "",  
    redirectUri: "",  
  },
  cache: {
    cacheLocation: "localStorage", 
    storeAuthStateInCookie: false,
  },
};

export let msalInstance = new PublicClientApplication(defaultMsalConfig);

export const initializeMsalInstance = async () => {
  try {

    const msalConfig = getMsalConfig();
    msalInstance = new PublicClientApplication(msalConfig);

    await msalInstance.initialize();

    // Default to using the first account if no account is active on page load
    if (
      !msalInstance.getActiveAccount() &&
      msalInstance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0] ?? null);
    }

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const account = event.payload as AccountInfo;
        msalInstance.setActiveAccount(account);

        // Extract userId from idTokenClaims and store it in localStorage
        const userId = (account.idTokenClaims?.oid || account.idTokenClaims?.sub) ?? "";
        UserUtility.SetUserId(userId);

        // Generate a GUID for sessionId and store it in localStorage
        SessionUtility.SetSessionId();

        // Dispatch a custom event to notify other parts of the application
        const loginSuccessEvent = new CustomEvent('custom:loginSuccess', { detail: account });
        setTimeout(() => {
          window.dispatchEvent(loginSuccessEvent);
        }, 10000); // Delay emission by 10 seconds
      }
    });

    await msalInstance.handleRedirectPromise();
    console.info('msal: loginRedirect success');
  } catch (error) {
    console.error('msal: loginRedirect failure', error);
  }
};