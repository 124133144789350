import React from 'react'; // Import React
import { TextField, styled } from "@mui/material";
import { theme } from '../../../../styles/theme';

const backgroundColor = "#141D27";
const borderColor = "#E8ECED";
const borderWidth = "2px";
const disabledTextColor = theme.palette.text.primary;
const inputFontSize = theme.typography.body1.fontSize;
const labelFontSize = theme.typography.body1.fontSize;
const textColor = theme.palette.text.primary;

const TextFieldCustom = styled(TextField)({
  "& .MuiInputLabel-root": {
    color: textColor,
    transform: "translate(14px, -20px) scale(1)",
  },
  "& .MuiInputBase-root": {
    marginLeft: "6px",
    marginRight: "6px",
    marginTop: "14px",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "transparent",
    "& fieldset": {
      borderColor: borderColor,
      borderWidth: borderWidth,
    },
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: borderColor,
    },
    color: textColor,
    backgroundColor: backgroundColor,
  },
  "& .MuiInputBase-input": {
    color: textColor,
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: disabledTextColor,
  },
  "& label.Mui-focused": {
    color: textColor
  },
});

// Use React.forwardRef to forward refs
export const StyledTextField = React.forwardRef((
  {
    maxlength = -1,
    ...props
  }: {
    maxlength?: number;
    [key: string]: any;
  },
  ref: React.Ref<HTMLDivElement> // Specify the correct type for the ref prop
) => {
  return (
    <TextFieldCustom
      ref={ref} // Forward the ref to TextFieldCustom
      margin="normal"
      variant="outlined"
      inputProps={{ style: { fontSize: inputFontSize, padding: 6, margin: '3px 5px' }, maxLength: maxlength === -1 ? undefined : maxlength }}
      InputLabelProps={{
        shrink: false,
        style: { fontSize: labelFontSize, left: "-8px", color: textColor },
      }}
      {...props}
    />
  );
});