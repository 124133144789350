import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { ISelectedClientApplication } from "./selectedClientApplication.context.model";
import { Client } from "../../models/client/client.model";
import { ClientService } from "../../../service/api/client/clientService";
import { useGlobalProgressBarService } from "../globalProgressBar/globalProgressBar.Context";
import { localStorageConst } from "../../../constants/global.constants";
import { useIsAuthenticated } from "@azure/msal-react";

interface ClientApplicatonContextType {
  selectedClientApp: ISelectedClientApplication | null;
  selectClientApp: (clientApp: ISelectedClientApplication) => void;
  loading: boolean;
}

export const ClientAppContext = createContext<
  ClientApplicatonContextType | undefined
>(undefined);

export const useSelectedClientApp = () => {
  const context = useContext(ClientAppContext);
  if (context === undefined) {
    throw new Error(
      "useSelectedClientApp must be used within a ClientAppProvider"
    );
  }
  return context;
};


export const ClientAppProvider = ({ children }: { children: ReactNode }) => {
  const clientService = new ClientService();
  const [selectedClientApp, setSelectedClientApp] =
    useState<ISelectedClientApplication | null>(null);

  const [loading, setLoading] = useState(true);

  const isAuthenticated = useIsAuthenticated();

  const selectClientApp = (clientApp: ISelectedClientApplication) => {
    setSelectedClientApp(clientApp);
    localStorage.setItem(localStorageConst.SELECTED_CLIENT_APP, JSON.stringify({ id: clientApp.clientApp.id }));
    localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER); 
  };

  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();
  
  useEffect(() => {
  
    const initialize = async () => {

      try {
        addProgressItem();

        const clients = (await clientService.getClientsService()).data as Client[];
        
        if (clients?.length > 0) {

          const savedClientApp = localStorage.getItem(localStorageConst.SELECTED_CLIENT_APP);

          if (savedClientApp) {
            const clientApp = JSON.parse(savedClientApp);
            const client = clients.find((c) => c.ClientId === clientApp.id);
  
            if (client) {
              const clientClientApp: ISelectedClientApplication = {
                clientApp: { id: client.ClientId, name: client.ClientName, description: client.ClientName, tags: client.Tags },
              };
              
              setSelectedClientApp(clientClientApp);
              localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER);
            }
            else {
              const firstClientApp: ISelectedClientApplication = {
                clientApp: { id: clients[0].ClientId, name: clients[0].ClientName, description: clients[0].ClientName, tags: clients[0].Tags },
              };
  
              setSelectedClientApp(firstClientApp);
              localStorage.setItem(localStorageConst.SELECTED_CLIENT_APP, JSON.stringify({ id: firstClientApp.clientApp.id }));
              localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER);
            }
          }
          else {
            const firstClientApp: ISelectedClientApplication = {
              clientApp: { id: clients[0].ClientId, name: clients[0].ClientName, description: clients[0].ClientName, tags: clients[0].Tags },
            };
  
            setSelectedClientApp(firstClientApp);
            localStorage.setItem(localStorageConst.SELECTED_CLIENT_APP, JSON.stringify({ id: firstClientApp.clientApp.id }));
            localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER);
          }
        }
        else {
          setSelectedClientApp(null);
          localStorage.removeItem(localStorageConst.SELECTED_CLIENT_APP);
          localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER); 
        }
      }
      catch {
        setSelectedClientApp(null);
        localStorage.removeItem(localStorageConst.SELECTED_CLIENT_APP);
        localStorage.removeItem(localStorageConst.SELECTED_CUSTOMER);
      }
      finally {
        removeProgressItem();
        setLoading(false);
      }
    };

    const initializeAsync = async () => {
      await initialize();
    }

    if (isAuthenticated) {
      initializeAsync();
    }
  
  // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <ClientAppContext.Provider value={{ selectedClientApp, selectClientApp, loading }}>
      {children}
    </ClientAppContext.Provider>
  );
};