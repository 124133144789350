import {
    Box
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {theme} from "../../../../../styles/theme";
import {SearchUNPortsResult} from "../../../../models/port/port.model";
import Search from "./search.component";
import {DataGridPort} from "./dataGridPort.component";
import {UnecePort} from "../../../../models/port/protoPort.model";

export const dataGridPageSize = 50;

export interface DataRequest {
    sort?: string;
    sortOrder?: string;
    pageNumber: number;
    pageSize: number;
}

interface ComponentProperties {
    setSelectedUNPort: (value: UnecePort) => void;
    reset: boolean
}

export const SearchUnecePorts = (props: ComponentProperties) => {
    const [data, setData] = useState<SearchUNPortsResult>({ data: [], dataSource: []} as SearchUNPortsResult);
    const [dataRequest, setDataRequest] = useState<DataRequest>();
    const [isNewSearch, setIsNewSearch] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(false);

    useEffect(() => {
        if (props.reset) {
            setData({ data: [], dataSource: []} as SearchUNPortsResult);
        }
    }, [props.reset]);

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: theme.background.page,
                borderColor: theme.background.componentLight,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
                padding: "5px 6px 3px 6px",
            }}
        >
            <Search
                onSearchDoneHandler={setData}
                setIsNewSearch={setIsNewSearch}
                setIsDataLoading={setIsDataLoading}
                dataRequest={dataRequest}
                reset={props.reset} />
            <DataGridPort
                data={data.data}
                dataSource={data.dataSource}
                initDataRequest={setDataRequest}
                isNewSearch={isNewSearch}
                isDataLoading={isDataLoading}
                setSelectedUNPort={props.setSelectedUNPort} />
        </Box>
    );
};

export default SearchUnecePorts;
