import { ErrorLog } from "../../shared/models/shared/error-log/errorLog.model";

export const logError = (errordData: ErrorLog) => {
  console.error("Global error caught:", {
    message: errordData.message,
    source: errordData.source,
    lineno: errordData.lineno,
    colno: errordData.colno,
    error: errordData.error,
  });
};