export enum BrowserType {
    CHROME = "chrome",
    FIREFOX = "firefox",
    SAFARI = "safari",
    EDGE = "edge",
    OTHER = "other",
}

export const detectBrowser = () => {
    const userAgent = navigator.userAgent;
  
    if (userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Edg') === -1) {
      return BrowserType.CHROME;
    } else if (userAgent.indexOf('Firefox') > -1) {
      return BrowserType.FIREFOX;
    } else if (userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1) {
      return BrowserType.SAFARI;
    } else if (userAgent.indexOf('Edg') > -1) {
      return BrowserType.EDGE;
    } else {
      return BrowserType.OTHER;
    }
  };