import { ISelectedCustomer } from "../../context/customer/selectedCustomer.context.model";
import { EcdisInformation } from "../shared/partner/ecdisInformation.model";
import { ErpInformation } from "../shared/partner/erpInformation.model";
import { Partners } from "../shared/partner/partners.model";
import { VesonInformation } from "../shared/partner/vesonInformation.model";
import { VesselAssignment } from "../shared/partner/vesselAssignment.model";
import { Vessel } from "../shared/vessel/vessel.model";

export interface ICustomer extends IBaseCustomer {
  clientId: number;
  commonCoreCloudId: string;
}

export interface IBaseCustomer {
  id: string;
  name: string;
  client_tenant_id: string;
  partners: Partners;
  vessels?: Vessel[] | null;
}

export type Customer = ICustomer;

export function isSameCustomer(
  customer1: ICustomer,
  customer2: ISelectedCustomer
): boolean {
  return (
    customer1.id === customer2.id &&
    customer1.name === customer2.name &&
    customer1.client_tenant_id === customer2.client_tenant_id &&
    isSamePartners(customer1.partners, customer2.partners)
  );
}

function isSamePartners(partner1: Partners, partner2: Partners): boolean {
  return (
    compareVesonInformation(
      partner1.vesonInformation,
      partner2.vesonInformation
    ) &&
    compareEcdisInformation(
      partner1.ecdisInformation,
      partner2.ecdisInformation
    ) &&
    compareErpInformation(partner1.erpInformation, partner2.erpInformation)
  );
}

function compareVesonInformation(
  veson1?: VesonInformation,
  veson2?: VesonInformation
): boolean {
  if (!veson1 && !veson2) {
    return true;
  }
  if (!veson1 || !veson2) {
    return false;
  }

  return (
    veson1.vesonInternalId === veson2.vesonInternalId &&
    veson1.vesonToken === veson2.vesonToken
  );
}

function compareEcdisInformation(
  ecdis1?: EcdisInformation,
  ecdis2?: EcdisInformation
): boolean {
  return (
    (ecdis1 == null && ecdis2 == null) || (ecdis1 != null && ecdis2 != null)
  );
}

function compareErpInformation(
  erp1?: ErpInformation,
  erp2?: ErpInformation
): boolean {
  if (!erp1 && !erp2) {
    return true;
  }

  if (!erp1 || !erp2) {
    return false;
  }

  return compareVesselAssignments(erp1.vesselAssignment, erp2.vesselAssignment);
}

function compareVesselAssignments(
  vesselAssignment1: VesselAssignment[],
  vesselAssignment2: VesselAssignment[]
): boolean {
  if (vesselAssignment1.length !== vesselAssignment2.length) {
    return false;
  }

  const vesselAssignments2Copy = [...vesselAssignment2];

  for (let i = 0; i < vesselAssignment1.length; i++) {
    const matchingIndex = vesselAssignments2Copy.findIndex(
      (a) =>
        a.wcn === vesselAssignment1[i].wcn &&
        a.accessToken === vesselAssignment1[i].accessToken
    );
    if (matchingIndex === -1) {
      return false;
    }

    vesselAssignments2Copy.splice(matchingIndex, 1);
  }

  return vesselAssignments2Copy.length === 0;
}

export {};
