import { EcdisInformation } from "../partner/ecdisInformation.model";
import { ErpInformation } from "../partner/erpInformation.model";
import { Partners } from "../partner/partners.model";
import { VesonInformation } from "../partner/vesonInformation.model";

export interface ISignalRMessage {
    ActionType: string;
    ClientId: string;
    ClientTenantId: string;
	Id: string;
    Name: string;
    Partners: {
        VesonInformation: {
            VesonInternalId: string;
            HasVesonInternalId: boolean;
            VesonToken: string;
            HasVesonToken: boolean;
            QueueNames: string[];
        };
        EcdisInformation: {
            CreateResources: boolean;
            HasCreateResources: boolean;
            ConnectionString: string;
            HasConnectionString: boolean;
            QueueNames: string[];
        };
        KongsbergInformation: any;
        ErpInformation: {
            VesselAssignment: {
                SubdivisionName: string;
                HasSubdivisionName: boolean;
                Wcn: string;
                HasWcn: boolean;
                AccessToken: string;
                HasAccessToken: boolean;
                VesselImo: string;
                HasVesselImo: boolean;
            }[];
            QueueNames: string[];
            ExchangeNames: string[];
        };
    };
  }

export function convertPartners(partners: ISignalRMessage["Partners"]): Partners {
  if (partners == null) {
    return {} as Partners;
  }

  let vesonInformation : VesonInformation | undefined = undefined;
  if (partners.VesonInformation != null ) {
    vesonInformation = {
      vesonInternalId: partners.VesonInformation.VesonInternalId,
      vesonToken: partners.VesonInformation.VesonToken,
      queueNames: partners.VesonInformation.QueueNames,
    };
  }

  let ecdisInformation : EcdisInformation | undefined = undefined;
  if (partners.EcdisInformation != null) {
    ecdisInformation = {
      createResources: partners.EcdisInformation.CreateResources,
      connectionString: partners.EcdisInformation.ConnectionString,
      queueNames: partners.EcdisInformation.QueueNames,
    };
  }

  let erpInformation : ErpInformation | undefined = undefined;
  if (partners.ErpInformation != null) {
    erpInformation = {
      vesselAssignment: partners.ErpInformation.VesselAssignment.map(
        (assignment) => ({
          subdivisionName: assignment.SubdivisionName,
          wcn: assignment.Wcn,
          accessToken: assignment.AccessToken,
        })
      ),
      queueNames: partners.ErpInformation.QueueNames,
      exchangeNames: partners.ErpInformation.ExchangeNames,
    };
  }

  return {
    vesonInformation,
    ecdisInformation,
    erpInformation,
  };
}

export {};