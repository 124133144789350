import { SyncDisabled } from "@mui/icons-material";
import { CssBaseline, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { theme } from "../../../styles/theme";
import logo from "../../../assets/logo.png";

export const ErrorPage = () => {
  return (
    <>
      <CssBaseline />
      <Box
        data-testid="error-component"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background.page,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <SyncDisabled
          fontSize={"large"}
          sx={{ color: theme.palette.text.disabled, marginBottom: "12px" }}
        />
        <Box component="img" alt="ABS Logo" src={logo} />
        <Box>
          <Typography sx={{ fontSize: "30px", margin: "30px" }}>
            Unable to load the page
          </Typography>
        </Box>
      </Box>
    </>
  );
};
