import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Stack,
  Switch
} from "@mui/material";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { UndoButton } from "../styled/button/styledUndoButton.component";
import { useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelectedCustomer } from "../../context/customer/customerSelection.Context";
import { ModuleNames } from "../../models/shared/module/ModuleNames";
import { StyledTextField } from "../styled/textfield/styledTextField.component";
//import { validate as uuidValidate } from "uuid"

const moduleName = ModuleNames.Voyage;

interface IVoyagePlanningTrackingModuleProps {
  handleUndo: (moduleName: string) => void;
  disableUndo: boolean;
  handleDisable: (moduleName:ModuleNames, value:boolean) => void;
  handleChange: (moduleName:ModuleNames) => void;
}

const VoyagePlanningTrackingModule = (
  props: IVoyagePlanningTrackingModuleProps
) => {
  const { t } = useTranslation();
  const {
    getValues,
    register,
    formState: { errors },
    control,
    setValue,
    trigger,
    watch
  } = useFormContext();

  const { selectedCustomer } = useSelectedCustomer();

  const [open, setOpen] = useState(true);

  const vesonSwitchState = useWatch({ control, name: "IsActiveVeson" });

  const handleExpandItems = () => {
    setOpen(!open);
  };

  const [contentChanged, setContentChanged] = useState(false);

  useEffect(() => {
    setContentChanged(false);
  }, [selectedCustomer, props.disableUndo]);

  const vesonInternalId = watch("vesonInformation.vesonInternalId", "");
  const vesonToken = watch("vesonInformation.vesonToken", "");

  useEffect(() => {
    setValue("vesonInformation.vesonInternalId", getValues("vesonInformation.vesonInternalId"),{
      shouldValidate: true,
    });
    setValue("vesonInformation.vesonToken", getValues("vesonInformation.vesonToken"), {
      shouldValidate: true,
    });
    trigger(["vesonInformation.vesonInternalId", "vesonInformation.vesonToken"]);
  }, [vesonSwitchState, setValue, getValues, trigger, vesonInternalId, vesonToken]);

  return (
    <Box
      key={"module_VoyagePlanningTracking"}
      sx={{
        backgroundColor: theme.background.componentLight,
        color: theme.palette.secondary.contrastText,
        padding: "1rem",
        borderRadius: "0.5rem",
        marginBottom: "1rem",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box textAlign="left">
          <h1>{t("component.modules.voyage_planning_tracking")}:</h1>
        </Box>
        <Box
          textAlign="right"
          justifyContent={"flex-end"}
          alignItems="center"
          margin="auto"
        >
          <UndoButton
            id="UndoButtonVoyage"
            disabled={!contentChanged}
            onClick={() => {
              props.handleUndo(moduleName);
              setContentChanged(false);
              props.handleDisable(moduleName, false);
            }}
          />
        </Box>
      </Stack>
      <Box
        key={"adapter_Veson"}
        sx={{
          backgroundColor: theme.background.component,
          color: theme.palette.secondary.contrastText,
          borderRadius: "15px",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          key={"adapter_wrapper_Veson"}
          sx={{
            backgroundColor: theme.background.componentLight,
            width: "100%",
          }}
        ></Box>

        <Box
          key={"toggle_wrrapper_Veson"}
          sx={{
            backgroundColor: theme.colors.darkBlue,
            borderRadius: "15px 15px 0 0",
            padding: "1rem",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Box>
              <Controller
                name="IsActiveVeson"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    key={"toggle_label_Veson"}
                    control={
                      <Switch
                        key={"IsActiveVeson"}
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setContentChanged(true)
                          props.handleDisable(moduleName, true);
                          props.handleChange(moduleName);
                        }}
                      />
                    }
                    label={t("routes.general.veson_voyage_plan")}
                  />
                )}
              />
            </Box>

            <Box textAlign="right" alignItems="center" margin="auto">
              <Button onClick={handleExpandItems}>
                {open ? (
                  <ExpandLess sx={{ fontSize: "2rem", mr: 1 }}></ExpandLess>
                ) : (
                  <ExpandMore sx={{ fontSize: "2rem", mr: 1 }}></ExpandMore>
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
        <br />
        <Collapse in={open} timeout="auto" unmountOnExit>
          <StyledTextField
            {...register("vesonInformation.vesonInternalId", {
              required: vesonSwitchState ? true : false,
            })}
            id={"vesonInformation.vesonInternalId"}
            key={"vesonInformation.vesonInternalId"}
            label={t("routes.general.vesson_id")}
            sx={{ width: "40%" }}
            maxlength={50}
            onChange={(e: any) => {
              setValue("vesonInformation.vesonInternalId", e.target.value, {
                shouldValidate: true,
              });
              setContentChanged(true);
              props.handleDisable(moduleName, true);
              props.handleChange(moduleName);
            }}
            onBlur={() => {
              trigger("vesonInformation.vesonInternalId");
            }}
            error={Boolean((errors.vesonInformation as any)?.vesonInternalId)}
            helperText={
              (errors.vesonInformation as any)?.vesonInternalId &&
              t("routes.general.required")
            }
            disabled={!vesonSwitchState}
          />

          <StyledTextField
            {...register("vesonInformation.vesonToken", {
              required: vesonSwitchState ? true : false,
              // validate: vesonSwitchState
              //   ? (value) => uuidValidate(value) || "INVALID GUID" : undefined,//TODO remove this or activate when we are sure for this limitation
            })}
            id={"vesonInformation.vesonToken"}
            key={"vesonInformation.vesonToken"}
            label={t("routes.general.vesson_token")}
            sx={{ width: "60%" }}
            //inputProps={{ maxLength: 50 }} //TODO remove this or activate when we are sure for this limitation
            onChange={(e: any) => {
              setValue("vesonInformation.vesonToken", e.target.value, {
                shouldValidate: true,
              });
              setContentChanged(true);
              props.handleDisable(moduleName, true);
              props.handleChange(moduleName);
            }}
            onBlur={() => {
              trigger("vesonInformation.vesonToken");
            }}
            error={Boolean((errors.vesonInformation as any)?.vesonToken)}
            helperText={
              (errors.vesonInformation as any)?.vesonToken?.message === "INVALID GUID"
                ? t("routes.general.invalid_uuid")
                : (errors.vesonInformation as any)?.vesonToken && t("routes.general.required")
            }
            disabled={!vesonSwitchState}
          />
        </Collapse>
      </Box>{" "}
      {/* adapter_Veson */}
      <Box
        key={"adapter_ECDIS"}
        sx={{
          backgroundColor: theme.background.component,
          color: theme.palette.secondary.contrastText,
          borderRadius: "15px",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          key={"adapter_wrapper_ECDIS"}
          sx={{
            backgroundColor: theme.background.componentLight,
          }}
        ></Box>

        <Box
          key={"toggle_wrrapper_ECDIS"}
          sx={{
            backgroundColor: theme.colors.darkBlue,
            borderRadius: "15px 15px 15px 15px",
            padding: "1rem",
          }}
        >
          <Controller
            name="IsActiveEcdis"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                key={"toggle_label_Ecdis"}
                control={
                  <Switch
                    key={"IsActiveEcdis"}
                    checked={value}
                    onChange={(e) => {
                      onChange(e.target.checked);
                      setContentChanged(true);
                      props.handleDisable(moduleName, true);
                      props.handleChange(moduleName);
                    }}
                  />
                }
                label={t("routes.general.ecdis_waypoints")}
              />
            )}
          />
        </Box>
      </Box>{" "}
      {/* adapter_ECDIS */}
    </Box>
  );
};

export default VoyagePlanningTrackingModule;