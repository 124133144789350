import Button from "@mui/material/Button";
import { theme } from "../../../../styles/theme";
import ReplayTwoToneIcon from "@mui/icons-material/ReplayTwoTone";

interface StyledUndoButtonProps {
  id: string;
  disabled?: boolean;
  onClick: () => void;
}

const enabledForeColor = theme.palette.text.primary;

export const UndoButton: React.FC<StyledUndoButtonProps> = ({
  id,
  onClick,
  disabled
}) => {
  return (
    <>
      <Button sx={{ color: enabledForeColor }} disabled={disabled} id={id}>
        <ReplayTwoToneIcon
          sx={{ fontSize: "2rem", mr: 1 }}
          onClick={() => onClick()}
        />
      </Button>
    </>
  );
};