import React from "react";
import {
    Box,
    FormControlLabel,
    Checkbox
} from "@mui/material";
import { theme } from "../../../../styles/theme";
import { useFormContext } from "react-hook-form";
import { Function } from "../../../models/port/protoPort.model";

const labelStyle = {
    marginLeft: 0,
    marginRight: 0,
    "& .MuiFormControlLabel-label": {
        fontSize: "12px",
    },
    "&.Mui-disabled svg": {
        color: theme.palette.text.primary
    }
};

interface ComponentProperties {
  portFunctions: Function[];
  selectedPortFunctions: Function[];
  isUnecePortAlias: boolean;
}

export const PortFunctionsColumn: React.FC<ComponentProperties> = ({ portFunctions, selectedPortFunctions, isUnecePortAlias }) => {
    const {
        getValues,
        setValue,
    } = useFormContext();

    const checkBoxClickedHandler = (event: any) => {
        const formPortFunctions = getValues().portFunctions as Function[];
        const index = formPortFunctions.findIndex(i => i.code === event.target.value);
        if(event.target.checked) {
            if (index < 0) {
                formPortFunctions.push({ code: event.target.value, description: null } as Function);
                setValue("portFunctions", formPortFunctions, {
                    shouldValidate: true
                });
            }
        } else {
            if (index >= 0) {
                formPortFunctions.splice(index, 1);
                setValue("portFunctions", formPortFunctions, {
                    shouldValidate: true
                });
            }
        }
    }

    return (
        <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.background.page,
              "& .Mui-disabled": {
                  backgroundColor: theme.background.componentLight
              }
            }}
        >
            {portFunctions.map((portFunc: Function) => (
              <FormControlLabel
                  sx={labelStyle}
                  control={
                  <Checkbox
                      value={portFunc.code}
                      key={`port-func-control-${portFunc.code}`}
                      onClick={checkBoxClickedHandler}
                      disabled={isUnecePortAlias}
                      checked={selectedPortFunctions.some(f => f.code === portFunc.code)}
                  />}
                  label={portFunc.description}
                  key={`port-func-label-${portFunc.code}`}
              />
            ))}
        </Box>
    );
};