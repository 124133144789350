// Purpose: Constants for the customer service.
export const path = {
  TENANT_ADAPTERS_GET_TENANTS: "/tenantadapters/GetTenants",
  TENANT_ADAPTERS_CREATE_NEW_TENANT: "/tenantadapters/CreateNewTenant",
  TENANT_ADAPTERS_REGISTER_WITH_PARTNER: "/tenantadapters/RegisterWithPartner",
  TENANT_ADAPTERS_UPDATE_TENANT: "/tenantadapters/UpdateTenant",
  TENANT_ADAPTERS_VALIDATE_PARTNERS_VESON: "/tenantadapters/partners/veson/Verify",
  TENANT_ADAPTERS_VALIDATE_PARTNERS_ECDIS: "/tenantadapters/partners/ecdis/Verify",
  TENANT_ADAPTERS_VALIDATE_PARTNERS_ERP: "/tenantadapters/partners/erp/Verify"
};

export const headerKey = {
  CC_ACTIVE_CLIENT: "cc-active-client",
  CC_TENANT_ID: "cc-tenantid",
};

export const paging = {
  MAX_PAGE_SIZE: 100,
};