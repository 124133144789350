import { createContext, ReactNode, useContext, useState } from "react";
import { IToastMessage } from "./toastMessage.context.model";
import { v4 as uuidv4 } from "uuid";

interface ToastMessageContextType {
  addToast: (
    severity: IToastMessage["severity"],
    title: string,
    messages?: string[]
  ) => void;
  removeToast: (id: string) => void;
  toasts: IToastMessage[];
}

export const ToastMessageContext = createContext<
  ToastMessageContextType | undefined
>(undefined);

export const useToastMessageService = () => {
  const context = useContext(ToastMessageContext);
  if (context === undefined) {
    throw new Error(
      "useToastMessageService must be used within a ToastMessageServiceProvider"
    );
  }
  return context;
};

export const ToastMessageProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<IToastMessage[]>([]);

  const addToast = (
    severity: IToastMessage["severity"],
    title: string,
    messages?: string[]
  ) => {
    const id = uuidv4();
    setToasts((prevToasts) => [
      ...prevToasts,
      { id, severity, title: title, messages: messages },
    ]);
  };

  const removeToast = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  return (
    <ToastMessageContext.Provider value={{ addToast, removeToast, toasts }}>
      {children}
    </ToastMessageContext.Provider>
  );
};
