import { Client } from "../../../shared/models/client/client.model";
import { BaseService } from "../BaseService";
import { paths } from "./clientService.constants";
import { clientConstants } from "../../../constants/global.constants";

export class ClientService extends BaseService {
  public async getClientsService() {
    return this.get<Client[]>(paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS);
  }

  public async getClientLastSyncDateTimeService(clientId: string): Promise<Date> {
    const responseData = await this.get<Client[]>(
      paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS
    );

    const client = responseData?.data?.find(
      (client) =>
        client.ClientId === clientId &&
        client.Tags?.toLowerCase().includes(clientConstants.MDF_KEYWORD)
    );

    return client?.LastSyncDatetime ? new Date(client.LastSyncDatetime) : new Date(new Date().toUTCString());
  }

  public async isClientMDFService(clientId: string): Promise<boolean> {
    const responseData = await this.get<Client[]>(
      paths.ONBOARDING_MIDDLEWARE_GET_CLIENTS
    );

    const client = responseData?.data?.find(
      (client) =>
        client.ClientId === clientId &&
        client.Tags?.toLowerCase().includes(clientConstants.MDF_KEYWORD)
    );

    return !!client;
  }
}