import { Box } from "@mui/material";
import ListCustomers from "./listCustomers.component";
import { SearchCustomers } from "./searchCustomer.component";
import { Customer, ICustomer } from "../../../models/customer/customer.model";
import { useEffect, useRef, useState } from "react";
import { SortingOption } from "../../../../utils/filters/FilterSettings";
import { theme } from "../../../../styles/theme";
import {
  BrowserType,
  detectBrowser,
} from "../../../../utils/browser/DetectBrowser";
import { useSelectedClientApp } from "../../../context/clientApplication/clientApplicationSelection.context";
import { useToastMessageService } from "../../../context/toast/toastMessage.Context";
import { CustomerService } from "../../../../service/api/customer/customerService";
import { ClientUtility } from "../../../../utils/data/client.utility";
import { useGlobalProgressBarService } from "../../../context/globalProgressBar/globalProgressBar.Context";
import { useSignalRService } from "../../../context/signalR/signalR.Context";
import { SignalRActionTypes } from "../../../models/shared/signalR/enum/signalRActionTypes";
import { SignalRTargets } from "../../../models/shared/signalR/enum/signalRTargets";

export const ListCustomerView = () => {
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortState, setSortState] = useState<SortingOption>(SortingOption.ASC);
  const [browser, setBrowser] = useState<string>("");
  const { selectedClientApp } = useSelectedClientApp();
  const { addToast } = useToastMessageService();
  const customerService = new CustomerService();
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const { signalRData } = useSignalRService();

  const isInitialRender = useRef(true);

  const [filteredCustomers, setFilteredCustomers] = useState<ICustomer[]>([]);

  // Fetch customer data from the API
  const fetchCustomers = async () => {
    try {
      addProgressItem();

      let selectedClientId = ClientUtility.GetSelectedClientId();
      if (selectedClientId !== null) {
        let response: Customer[] =
          await customerService.getCustomersByClientAsync(selectedClientId);
        setCustomers([...response]);
      } else {
        setCustomers([]);
      }
    } catch (error) {
      addToast("error", "Error fetching customer data:" + error);
    } finally {
      removeProgressItem();
    }
  };

  useEffect(() => {
    fetchCustomers();
    setBrowser(detectBrowser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleSort = () => {
    setSortState(
      sortState === SortingOption.ASC ? SortingOption.DESC : SortingOption.ASC
    );
  };

  useEffect(() => {
    const filteredCustomers = Array.isArray(customers)
      ? customers
          .filter((customer: ICustomer) =>
            customer.name.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .sort((a, b) => {
            if (sortState === SortingOption.ASC) {
              return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
            } else {
              return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1;
            }
          })
      : [];

    setFilteredCustomers(filteredCustomers);
    // eslint-disable-next-line
  }, [customers, searchQuery]);

  //TODO refactor this to a separate component
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (signalRData?.Target === SignalRTargets.TenantUpdate) {
      const customer: ICustomer | null = signalRData?.Data.customer ?? null;

      if (
        signalRData?.Data.clientId === ClientUtility.GetSelectedClientId() &&
        customer != null
      ) {
        if (signalRData?.Data.action === SignalRActionTypes.AddCustomer) {
          setCustomers((prevCustomers) => [...prevCustomers, customer]);
        } else if (
          signalRData?.Data.action === SignalRActionTypes.UpdateCustomer
        ) {
          const updatedCustomers = customers.map((c) => {
            if (c.id === customer.id) {
              return customer;
            }
            return c;
          });
          setCustomers(updatedCustomers);
        } else if (
          signalRData?.Data.action === SignalRActionTypes.DeleteCustomer
        ) {
          const updatedCustomers = customers.filter(
            (c) => c.id !== customer.id
          );
          setCustomers(updatedCustomers);
        }
      }
    }
    // eslint-disable-next-line
  }, [signalRData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        margin: 0,
        [theme.breakpoints.down("xl")]: {
          height: browser === BrowserType.CHROME ? "77vh" : "80vh",
        },
        [theme.breakpoints.down("lg")]: {
          height: browser === BrowserType.CHROME ? "70vh" : "77vh",
        },
        [theme.breakpoints.down("mdPlus")]: {
          height: browser === BrowserType.CHROME ? "65vh" : "70vh",
        },
      }}
    >
      <SearchCustomers
        searchQuery={searchQuery}
        onSearch={handleSearch}
        onSortOrder={handleSort}
      />
      <Box
        sx={{
          overflowY: "auto",
          marginTop: "8px",
        }}
      >
        <ListCustomers data={filteredCustomers} />
      </Box>
    </Box>
  );
};
