import {
  AppBar,
  Box,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import { theme } from "../../../styles/theme";
import { SignOutButton } from "../../../routes/login/components/SignOutButton";
import { useAppContext } from "../../context/app/app.context";
import { LanguageSelect } from "../language/languageSelect.component";
import { useTranslation } from "react-i18next";
import Version from "../left-panel/components/version.component";
import logo from "../../../assets/logo.png";
import { ClientApplicationSelect } from "../client-app/clientApplicationSelect.component";
import { SelectedCustomer } from "../customer/selectedCustomer.component";

const Header = () => {
  const appContext = useAppContext();
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        sx={{
          width: "100%",
          marginBottom: "3px",
          padding: "20px 10px 0px 10px",
          height: "110px",
          backgroundColor: theme.background.componentLight,
          color: theme.palette.secondary.contrastText,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "70%",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="left"
            direction="column"
            sx={{
              mb: 0.5,
              width: "28%",
              padding: 1,
            }}
          >
            <Box
              sx={{ margin: "2px", width: "100%" }}
              component="img"
              alt={t("component.leftpanel.abs_Logo")}
              src={logo}
            />
            <Version />
          </Stack>
          <Stack
            justifyContent="flex-start"
            direction="row"
            sx={{
              mb: 0.5,
              width: "72%",
              padding: 1,
              alignItems: "center",
            }}
          >
            <Box sx={{ width: "30%" }}>
              <ClientApplicationSelect />
            </Box>
            <Box sx={{ width: "70%" }}>
              <SelectedCustomer />
            </Box>
          </Stack>
        </Toolbar>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "30%",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            spacing={1}
          >
            <LanguageSelect />
            <Typography variant="menuItem" component="div" noWrap={true}>
              {appContext.user.name}
            </Typography>
            <SignOutButton />
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
