import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Box, Container, CssBaseline, LinearProgress } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import Header from "../../shared/components/header/header.component";
import { theme } from "../../styles/theme";
import { LeftPanel } from "../../shared/components/left-panel/components/leftPanel.component";
import NavigationComponent from "../../shared/components/navigation/navigation.component";
import { useIsAuthenticated } from "@azure/msal-react";
import { Login } from "../login/login.route";
import { BrowserType, detectBrowser } from "../../utils/browser/DetectBrowser";
import { useGlobalProgressBarService } from "../../shared/context/globalProgressBar/globalProgressBar.Context";
import { useMsal } from "@azure/msal-react";

interface RootProps {
  childComponent: ReactElement;
}

function handleStorageEvent(event: StorageEvent, instance: any) {
  if (event.key === "onboardingTool.logout") {
    instance.logoutRedirect();
  }
}

export function Root({ childComponent }: RootProps): EmotionJSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const [browser, setBrowser] = useState<string>("");
  const { isProgressBarActive } = useGlobalProgressBarService();
  const { instance } = useMsal();

  useEffect(() => {
    setBrowser(detectBrowser());

    // Event listener added to handle logout event from other tabs.
    // This is work around and code is located here to be able to use the MSAL instance
    const storageEventHandler = (event: StorageEvent) => handleStorageEvent(event, instance);

    window.addEventListener("storage", storageEventHandler);

    return () => {
      window.removeEventListener('storage', storageEventHandler);
    };
  }, [instance]);

  if (isAuthenticated) {
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.background.page,
        }}
      >
        <Box sx={{ maxwidth: "20%" }}>
          <CssBaseline />
          <Header />
        </Box>
        <Box sx={{ width: "100%", mb: "2px" }}>
          {isProgressBarActive() ? (
            <LinearProgress sx={{ height: "4px" }} />
          ) : (
            <Box sx={{ height: "4px" }} />
          )}
        </Box>
        <Box
          component="main"
          sx={{
            height: "auto",
            flexGrow: "1",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <LeftPanel />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              marginLeft: "8px",
            }}
          >
            <NavigationComponent />
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "100% !important",
                backgroundColor: theme.background.page,
                marginTop: "8px",
                height: "78vh",
                overflow: "auto",
                [theme.breakpoints.down("xl")]: {
                  height: browser === BrowserType.CHROME ? "75vh" : "78vh",
                },
                [theme.breakpoints.down("lg")]: {
                  height: browser === BrowserType.CHROME ? "68vh" : "75vh",
                },
                [theme.breakpoints.down("mdPlus")]: {
                  height: browser === BrowserType.CHROME ? "68vh" : "68vh",
                },
              }}
            >
              {childComponent}
            </Container>
          </Box>
        </Box>
      </Box>
    );
  }

  return <Login />;
}
