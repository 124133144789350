import { IconButton, InputAdornment, Stack } from "@mui/material";
import { StyledTextFieldTransparent } from "../../styled/textfield/styledTextFieldTransparent.component";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

interface SearchCustomersdProps {
  searchQuery: string;
  onSortOrder: () => void;
  onSearch: (query: string) => void;
}

export const SearchCustomers: React.FC<SearchCustomersdProps> = ({
  searchQuery,
  onSortOrder,
  onSearch,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleSortOrder = () => {
    onSortOrder();
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <StyledTextFieldTransparent
          id="searchCustomer"
          name="searchCustomer"
          placeholder={t("component.leftpanel.search_customer")}
          onKeyUp={handleChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton onClick={handleSortOrder}>
          <UnfoldMoreIcon />
        </IconButton>
      </Stack>
    </>
  );
};
