import { Box, Tooltip } from "@mui/material";
import { useSelectedClientApp } from "../../shared/context/clientApplication/clientApplicationSelection.context";
import { StyledHeader } from "../../shared/components/styled/header/styledHeader.component";
import { AddNewCustomer } from "../../shared/components/customer/add/addNewCustomer.component";
import { useSelectedCustomer } from "../../shared/context/customer/customerSelection.Context";
import { useEffect, useRef, useState } from "react";
import VoyagePlanningTrackingModule from "../../shared/components/modules/VoyagePlanningTrackingModule.component";
import EnvironmentalMonitorModule from "../../shared/components/modules/EnvironmentalMonitorModule.component";
import { PrimaryButton } from "../../shared/components/styled/button/styledButtonPrimary.component";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { VesonInformation } from "../../shared/models/shared/partner/vesonInformation.model";
import { EcdisInformation } from "../../shared/models/shared/partner/ecdisInformation.model";
import { VesselAssignment } from "../../shared/models/shared/partner/vesselAssignment.model";
import { ErpInformation } from "../../shared/models/shared/partner/erpInformation.model";
import { CustomerService } from "../../service/api/customer/customerService";
import { CRUDResponse } from "../../shared/models/shared/CRUDResponse.model";
import { ISelectedCustomer } from "../../shared/context/customer/selectedCustomer.context.model";
import { useToastMessageService } from "../../shared/context/toast/toastMessage.Context";
import { ModuleNames } from "../../shared/models/shared/module/ModuleNames";
import { useGlobalProgressBarService } from "../../shared/context/globalProgressBar/globalProgressBar.Context";
import { YesNoModal } from "../../shared/components/modal/yesNoModal.component";
import { ButtonActionTypes } from "../../shared/models/shared/button/buttonActionTypes";
import { ButtonTypes } from "../../shared/models/shared/button/buttonTypes";
import {
  Customer,
  isSameCustomer,
} from "../../shared/models/customer/customer.model";
import { useSignalRService } from "../../shared/context/signalR/signalR.Context";
import { SignalRActionTypes } from "../../shared/models/shared/signalR/enum/signalRActionTypes";
import { SignalRTargets } from "../../shared/models/shared/signalR/enum/signalRTargets";
import { CustomerUtility } from "../../utils/data/customer.utility";

export type FormFields = {
  clientName: string;
  client_id: string;
  common_core_cloud_id: string;
  vesonInformation: VesonInformation;
  IsActiveVeson: boolean;
  ecdisInformation: EcdisInformation;
  IsActiveEcdis: boolean;
  erpInformation: ErpInformation;
  IsActiveErp: boolean;
};

export function GeneralRoute() {
  const [isUpdateCustomerModalOpen, setIsUpdateCustomerModalOpen] =
    useState(false);
  const [isDeleteCustomerModalOpen, setIsDeleteCustomerModalOpen] =
    useState(false);
  const [isLeavePageModalOpen, setIsLeavePageModalOpen] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const signalRUpdateCustomerData = useRef<ISelectedCustomer | null>(null);

  const targetElementRef = useRef<HTMLElement | null>(null);

  const getFormData = (): FormFields => {
    let formFields: FormFields = {} as FormFields;

    if (selectedCustomer != null) {
      formFields = {
        clientName: selectedCustomer?.name ?? "",
        client_id: selectedCustomer?.client_tenant_id ?? "",
        common_core_cloud_id: selectedCustomer?.id ?? "",
        vesonInformation: {
          vesonInternalId:
            selectedCustomer?.partners?.vesonInformation?.vesonInternalId ?? "",
          vesonToken:
            selectedCustomer?.partners?.vesonInformation?.vesonToken ?? "",
          queueNames: [],
        },
        IsActiveVeson: selectedCustomer?.partners?.vesonInformation != null,
        ecdisInformation: {
          connectionString:
            selectedCustomer?.partners?.ecdisInformation?.connectionString ??
            "",
          createResources:
            selectedCustomer?.partners?.ecdisInformation?.createResources ??
            false,
          queueNames: [],
        },
        IsActiveEcdis: selectedCustomer?.partners?.ecdisInformation != null,
        erpInformation: {
          vesselAssignment: selectedCustomer?.partners?.erpInformation
            ?.vesselAssignment ?? [
            { wcn: "", accessToken: "" } as VesselAssignment,
          ],
          queueNames: [],
          exchangeNames: [],
        },
        IsActiveErp:
          selectedCustomer?.partners?.erpInformation?.vesselAssignment != null,
      };
    } else {
      formFields = {
        clientName: "",
        client_id: "",
        common_core_cloud_id: "",
        vesonInformation: {
          vesonInternalId: "",
          vesonToken: "",
          queueNames: [],
        },
        IsActiveVeson: false,
        ecdisInformation: {
          connectionString: "",
          createResources: false,
          queueNames: [],
        },
        IsActiveEcdis: false,
        erpInformation: {
          vesselAssignment: [
            { wcn: "", accessToken: "" },
          ] as VesselAssignment[],
          queueNames: [],
          exchangeNames: [],
        },
        IsActiveErp: false,
      };
    }

    return formFields;
  };

  const { selectedClientApp } = useSelectedClientApp();
  const { selectedCustomer, selectCustomer } = useSelectedCustomer();
  const { signalRData } = useSignalRService();

  const [isDeletedExternaly, setIsDeletedExternaly] = useState(false);

  const handleModalButtonClick = (actionType: string, buttonType: string) => {
    if (actionType === ButtonActionTypes.LeavePage) {
      setIsLeavePageModalOpen(false);
      if (buttonType === ButtonTypes.Yes) {
        setDisableSave(true);
        targetElementRef.current?.click();
      }
    } else if (actionType === ButtonActionTypes.UpdateCustomer) {
      setIsUpdateCustomerModalOpen(false);
      if (buttonType === ButtonTypes.Yes) {
        if (signalRData != null && signalRData.Data.customer != null) {
          selectCustomer(signalRUpdateCustomerData.current!);
        }
      }
    } else if (actionType === ButtonActionTypes.DeleteCustomer) {
      setIsDeleteCustomerModalOpen(false);
      if (buttonType === ButtonTypes.No) {
        setIsDeletedExternaly(true);
      } else {
        selectCustomer({} as ISelectedCustomer);
      }
    }
  };

  const [disableUndoCustomer, setDisableUndoCustomer] = useState(true);
  const [disableUndoVoyage, setDisableUndoVoyage] = useState(true);
  const [disableUndoERP, setDisableUndoERP] = useState(true);

  const [disableSave, setDisableSave] = useState(true);
  const [undoCustomer, setUndoCustomer] = useState(false);
  const [undoVoyage, setUndoVoyage] = useState(false);
  const [undoERP, setUndoERP] = useState(false);

  const[isChangedCustomer, setIsChangedCustomer] = useState(false);
  const customerRef = useRef<ISelectedCustomer | null>(null);

  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();

  const handleDisable = (moduleName: ModuleNames, value: boolean) => {
    if (moduleName === ModuleNames.Customer) {
      setUndoCustomer(value);
    } else if (moduleName === ModuleNames.Voyage) {
      setUndoVoyage(value);
    } else if (moduleName === ModuleNames.ERP) {
      setUndoERP(value);
    }
  };

  const handleCustomerChange = () => {
    if (selectedCustomer != null) {
      const clientName = getValues("clientName");

      if (selectedCustomer?.name === clientName) {
        setUndoCustomer(false);
        setDisableUndoCustomer(!disableUndoCustomer);
      }
    }
  };

  const handleVoyageChange = () => {
    if (selectedCustomer != null) {
      const vesonInternalId = getValues("vesonInformation.vesonInternalId");
      const vesonToken = getValues("vesonInformation.vesonToken");
      const IsActiveVeson = getValues("IsActiveVeson");
      const IsActiveEcdis = getValues("IsActiveEcdis");

      if (
        (selectedCustomer?.partners?.vesonInformation == null &&
          !IsActiveVeson &&
          selectedCustomer?.partners?.ecdisInformation == null &&
          !IsActiveEcdis) ||
        ((selectedCustomer?.partners?.vesonInformation?.vesonInternalId ??
          "") === vesonInternalId &&
          (selectedCustomer?.partners?.vesonInformation?.vesonToken ?? "") ===
            vesonToken &&
          (selectedCustomer?.partners?.vesonInformation != null) ===
            IsActiveVeson &&
          (selectedCustomer?.partners?.ecdisInformation != null) ===
            IsActiveEcdis)
      ) {
        setUndoVoyage(false);
        setDisableUndoVoyage(!disableUndoVoyage);
      }
    }
  };

  const handleERPChange = () => {
    if (selectedCustomer != null) {
      const vesselAssignment = getValues("erpInformation.vesselAssignment");
      const IsActiveErp = getValues("IsActiveErp");

      if (
        (selectedCustomer?.partners?.erpInformation?.vesselAssignment == null &&
          !IsActiveErp) ||
        ((selectedCustomer?.partners?.erpInformation?.vesselAssignment !=
          null) ===
          IsActiveErp &&
          JSON.stringify(
            selectedCustomer?.partners?.erpInformation?.vesselAssignment
          ) === JSON.stringify(vesselAssignment))
      ) {
        setUndoERP(false);
        setDisableUndoERP(!disableUndoERP);
      }
    }
  };

  const handleChange = (moduleName: ModuleNames) => {
    if (moduleName === ModuleNames.Customer) {
      handleCustomerChange();
    } else if (moduleName === ModuleNames.Voyage) {
      handleVoyageChange();
    } else if (moduleName === ModuleNames.ERP) {
      handleERPChange();
    }
  };

  const { addToast } = useToastMessageService();

  const { t } = useTranslation();
  const methods = useForm<FormFields>({
    defaultValues: getFormData(),
  });
  // eslint-disable-next-line
  const { formState, getValues, setValue, handleSubmit, reset } = methods;
  // eslint-disable-next-line
  const { errors, isDirty, isValid } = formState;

  useEffect(() => {
    if (!isValid || (!undoCustomer && !undoVoyage && !undoERP)) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [undoCustomer, undoVoyage, undoERP, isValid]);

  function isNewCustomer(selectedCustomer: ISelectedCustomer | null): boolean {
    return selectedCustomer == null || selectedCustomer.id === "";
  }

  async function isCustomerNameUniqueCI(
    customerName: string,
    omitCustomerId: string = ""
  ): Promise<boolean> {
    const customerService = new CustomerService();

    let customerNames: Customer[] =
      await customerService.getCustomersByClientAsync(
        selectedClientApp?.clientApp?.id.toString() ?? ""
      );
    let customerNamesArray: string[] = customerNames
      .filter((customer: Customer) => customer.id !== omitCustomerId)
      .map((customer: Customer) => customer.name.toUpperCase());

    return !customerNamesArray.includes(customerName.toUpperCase());
  }

  const updateCustomerData = (
    selectedCustomer: ISelectedCustomer,
    data: FormFields
  ): ISelectedCustomer => {
    return {
      ...selectedCustomer!,
      name: data.clientName,
      client_tenant_id: data.client_id,
      id: data.common_core_cloud_id,
      partners: {
        vesonInformation: data.IsActiveVeson
          ? data.vesonInformation
          : undefined,
        ecdisInformation: data.IsActiveEcdis
          ? data.ecdisInformation!
          : undefined,
        erpInformation: data.IsActiveErp ? data.erpInformation! : undefined,
      },
    };
  };

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    const customerService = new CustomerService();

    if (isNewCustomer(selectedCustomer) || isDeletedExternaly) {
      try {
        setRequestInProgress(true);
        addProgressItem();

        if (!(await isCustomerNameUniqueCI(data.clientName))) {
          removeProgressItem();
          addToast("error", t("routes.general.customer_name_is_not_unique"));
          return;
        }

        let crudResponse: CRUDResponse =
          await customerService.createNewCustomerAndRegisterWithPartnersAsync(
            selectedClientApp?.clientApp?.id.toString() ?? "",
            data
          );

        let successMessages: string[] = crudResponse.success?.map(String);
        let errorsMessages: string[] = crudResponse.errors?.map(String);

        successMessages.forEach((message: string) => {
          addToast("success", message);
        });

        errorsMessages.forEach((message: string) => {
          addToast("error", t("service.api.request_failed") + ": " + message);
        });

        if (successMessages.length > 0) {
          data.common_core_cloud_id = crudResponse?.data?.ccTenantId;
          data.client_id = "";
          setValue("common_core_cloud_id", data.common_core_cloud_id);
          setValue("client_id", data.client_id);

          customerRef.current = updateCustomerData(selectedCustomer!, data);
          setIsChangedCustomer(isChangedCustomer => !isChangedCustomer);
        }
      } catch (error: any) {
        addToast(
          "error",
          t("routes.general.error_creating_customer") + ":\n" + error.message
        );
      } finally {
        setRequestInProgress(false);
        removeProgressItem();
      }
    } else {
      try {
        setRequestInProgress(true);
        addProgressItem();

        if (
          !(await isCustomerNameUniqueCI(data.clientName, selectedCustomer!.id))
        ) {
          removeProgressItem();
          addToast("error", t("routes.general.customer_name_is_not_unique"));
          return;
        }

        let response: CRUDResponse = await customerService.updateCustomerAsync(
          selectedClientApp?.clientApp?.id.toString() ?? "",
          selectedCustomer!.id,
          data,
          selectedCustomer!
        );

        let successMessages: string[] = response.success?.map(String);
        let errorsMessages: string[] = response.errors?.map(String);

        successMessages.forEach((message: string) => {
          addToast("success", message);
        });

        errorsMessages.forEach((message: string) => {
          addToast("error", t("service.api.request_failed") + ": " + message);
        });

        //CHECK IF RESPONSE IS OK
        if (response.errors.length === 0) {
          customerRef.current = updateCustomerData(selectedCustomer!, data);
          setIsChangedCustomer(isChangedCustomer => !isChangedCustomer);
        }
      } catch (error: any) {
        addToast(
          "error",
          t("routes.general.error_updating_customer") + ":\n" + error.message
        );
      } finally {
        setRequestInProgress(false);
        removeProgressItem();
      }
    }
  };

  useEffect(() => {
    reset(getFormData());
    setDisableSave(true);
    setUndoCustomer(false);
    setUndoVoyage(false);
    setUndoERP(false);
    setIsDeletedExternaly(false);

    // eslint-disable-next-line
  }, [selectedCustomer, selectedClientApp]);

  useEffect(() => {
    if (
      customerRef.current != null &&
      (selectedCustomer == null || selectedCustomer.id === "" ||
        CustomerUtility.IsSelectedCustomer(customerRef.current.id))
    ) {
      selectCustomer(customerRef.current!);

      setDisableUndoCustomer(true);
      setDisableUndoVoyage(true);
      setDisableUndoERP(true);

      setDisableSave(true);
    }
    // eslint-disable-next-line
  }, [isChangedCustomer]);

  useEffect(() => {
    if (signalRData?.Target === SignalRTargets.TenantUpdate) {
      if (
        signalRData?.Data.action === SignalRActionTypes.UpdateCustomer &&
        signalRData.Data.customer.id === selectedCustomer?.id
      ) {
        const formData = getValues();
        const updatedCustomer = updateCustomerData(selectedCustomer!, formData);

        if (!isSameCustomer(signalRData.Data.customer, updatedCustomer)) {
          signalRUpdateCustomerData.current = signalRData.Data.customer;
          if (!isUpdateCustomerModalOpen) {
            setIsUpdateCustomerModalOpen(true);
          }
        } else {
          setIsUpdateCustomerModalOpen(false);
        }
      } else if (
        signalRData?.Data.action === SignalRActionTypes.DeleteCustomer &&
        signalRData.Data.customer.id === selectedCustomer?.id
      ) {
        setIsDeleteCustomerModalOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [signalRData]);

  const handleUndo = (moduleName: string) => {
    const formData = getFormData();

    if (moduleName === "customer") {
      setValue("clientName", formData.clientName);
      setValue("client_id", formData.client_id);
      setValue("common_core_cloud_id", formData.common_core_cloud_id);
    } else if (moduleName === "voyage") {
      setValue(
        "vesonInformation.vesonInternalId",
        formData.vesonInformation.vesonInternalId
      );
      setValue(
        "vesonInformation.vesonToken",
        formData.vesonInformation.vesonToken
      );
      setValue("IsActiveVeson", formData.IsActiveVeson);
      setValue("IsActiveEcdis", formData.IsActiveEcdis);
    } else if (moduleName === "erp") {
      setValue("IsActiveErp", formData.IsActiveErp);
      setValue(
        "erpInformation.vesselAssignment",
        formData.erpInformation.vesselAssignment
      );
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      const forbidenIds = [
        "VesselsTab",
        "PortsTab",
        "AddCustomerButton",
        "SignOutButton",
      ];
      const forbidenDataIds = ["ClientAppItem", "CustomerListItem"];

      let targetElement = event.target;
      let clickedForbidenElement = false;

      let isSaveDisabled = (
        document.getElementById("SaveButton") as HTMLButtonElement
      ).disabled;
      if (isSaveDisabled) {
        return;
      }

      let isUndoCustomerDisabled = (
        document.getElementById("UndoButtonCustomer") as HTMLButtonElement
      ).disabled;
      let isUndoVoyageDisabled = (
        document.getElementById("UndoButtonVoyage") as HTMLButtonElement
      ).disabled;
      let isUndoERPDisabled = (
        document.getElementById("UndoButtonERP") as HTMLButtonElement
      ).disabled;

      if (
        !(isUndoCustomerDisabled && isUndoVoyageDisabled && isUndoERPDisabled)
      ) {
        while (targetElement != null) {
          if (
            forbidenIds.includes(targetElement.id) ||
            forbidenDataIds.includes(targetElement?.dataset?.id)
          ) {
            clickedForbidenElement = true;
            break;
          }
          targetElement = targetElement.parentNode;
        }
      }

      if (clickedForbidenElement) {
        targetElementRef.current = targetElement;
        setIsLeavePageModalOpen(true);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <YesNoModal
          isOpen={isLeavePageModalOpen}
          actionType={ButtonActionTypes.LeavePage}
          onButtonClick={handleModalButtonClick}
          message={t("routes.general.question_to_leave_form_with_unsaved_data")}
        ></YesNoModal>
        <YesNoModal
          isOpen={isUpdateCustomerModalOpen}
          actionType={ButtonActionTypes.UpdateCustomer}
          onButtonClick={handleModalButtonClick}
          message={
            t("routes.general.customer_data_has_been_updated") +
            ". " +
            t("routes.general.accept_incoming_changes") +
            "?"
          }
        ></YesNoModal>
        <YesNoModal
          isOpen={isDeleteCustomerModalOpen}
          actionType={ButtonActionTypes.DeleteCustomer}
          onButtonClick={handleModalButtonClick}
          message={
            t("routes.general.customer_has_been_deleted") +
            ". " +
            t("routes.general.continue_with_your_updates") +
            "?"
          }
        ></YesNoModal>

        <Box
          sx={{
            position: "relative",
            right: "20%",
            top: 0,
            float: "right",
          }}
        >
          <Tooltip
            title={
              requestInProgress
                ? t("service.api.another_request_in_progress")
                : ""
            }
          >
            <span>
              <PrimaryButton
                id="SaveButton"
                type="submit"
                variant="contained"
                disabled={disableSave || requestInProgress}
              >
                {t("component.customer.save")}
              </PrimaryButton>
            </span>
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
          }}
        >
          <AddNewCustomer
            handleUndo={handleUndo}
            disableUndo={disableUndoCustomer}
            handleDisable={handleDisable}
            handleChange={handleChange}
          />
          <Box sx={{ marginTop: "15px" }}>
            <StyledHeader label={t("component.modules.modules")} />
            <VoyagePlanningTrackingModule
              handleUndo={handleUndo}
              disableUndo={disableUndoVoyage}
              handleDisable={handleDisable}
              handleChange={handleChange}
            />
            <EnvironmentalMonitorModule
              handleUndo={handleUndo}
              methods={methods}
              disableUndo={disableUndoERP}
              handleDisable={handleDisable}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
