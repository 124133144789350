import {
    Box,
    Divider,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    styled,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Typography
} from "@mui/material";
import React, {
    useEffect,
    useState
} from "react";
import i18n from 'i18next';
import {FormProvider, useForm, UseFormGetValues, useWatch, FieldPath} from "react-hook-form";
import {theme} from "../../../../styles/theme";
import {StyledTextField} from "../../styled/textfield/styledTextField.component";
import {CreateCustomPort, CustomPort, Function, Status, UnecePort} from "../../../models/port/protoPort.model";
import {CustomPortService} from "../../../../service/api/port/portService";
import {PortFunctions} from "./portFunctions.component";
import {OkCancelModal} from "../../modal/okCancelModal.component";
import {ButtonTypes} from "../../../models/shared/button/buttonTypes";
import {useToastMessageService} from "../../../context/toast/toastMessage.Context";
import {useGlobalProgressBarService} from "../../../context/globalProgressBar/globalProgressBar.Context";
import {UndoButton} from "../../styled/button/styledUndoButton.component";

const textColor = theme.palette.text.primary;
const labelFontSize = theme.typography.body1.fontSize;
const backgroundColor = "#141D27";
const borderColor = "#E8ECED";
const borderWidth = "2px";

const CustomStyledTextField = styled(StyledTextField)({
    minWidth: "170px",
    maxWidth: "220px"
});

const maxVisibleSelectedPortStatusDescriptionLength: number = 62;

const SelectCustom = styled(Select)({
    backgroundColor: backgroundColor,
    "& .MuiInputLabel-root": {
        color: textColor,
        WebkitTextFillColor: textColor,
        transform: "translate(14px, -20px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
        WebkitTextFillColor: textColor,
        color: textColor,
    },
    "& .Mui-disabled": {
        color: textColor,
        WebkitTextFillColor: textColor,
        backgroundColor: theme.background.componentLight
    },
    "&.Mui-disabled svg": {
        display: "none"
    },
    "&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: borderColor,
        borderWidth: borderWidth
    },
    "&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: borderColor
    }
});

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 400,
    },
});

export type FormFields = {
    portCode: string;
    portAlias: string;
    portName: string;
    portCountry: string;
    portLocation: string;
    portSubdivision: string;
    unLocode: string;
    portLongitude: string;
    portLatitude: string;
    portStatus: string;
    portFunctions: Function[];
};

const getFormData = (customPort: CustomPort | null): FormFields => {
    if (customPort) {
        return getCustomPortFormData(customPort);
    } else {
        return getDefaultFormData();
    }
}

const getDefaultFormData = (): FormFields => {

    let formFields: FormFields;

    formFields = {
        portCode: "",
        portAlias: "",
        portName: "",
        portCountry: "",
        portLocation: "",
        portSubdivision: "",
        unLocode: "",
        portLatitude: "",
        portLongitude: "",
        portStatus: "",
        portFunctions: []
    } as FormFields;

    return formFields;
};

const getUnecePortFormData = (unecePort: UnecePort): FormFields => {

    let formFields: FormFields;

    formFields = {
        portCode: "",
        portAlias: "",
        portName: unecePort.name ?? "",
        portCountry: unecePort.country?.code ?? "",
        portLocation: unecePort.location?.code ?? "",
        portSubdivision: unecePort.subdivision?.code ?? "",
        unLocode: unecePort.locode ?? "",
        portLatitude: unecePort.location?.latitude ?? "",
        portLongitude: unecePort.location?.longitude ?? "",
        portStatus: unecePort.status?.code ?? "",
        portFunctions: unecePort.functions ?? []
    } as FormFields;

    return formFields;
};

const getCustomPortFormData = (customPort: CustomPort): FormFields => {

    let formFields: FormFields;

    formFields = {
        portCode: customPort.code,
        portAlias: customPort.name,
        portName: customPort.port.name ?? "",
        portCountry: customPort.port.country?.code ?? "",
        portLocation: customPort.port.location?.code ?? "",
        portSubdivision: customPort.port.subdivision?.code ?? "",
        unLocode: customPort.port.locode ?? "",
        portLatitude: customPort.port.location?.latitude ?? "",
        portLongitude: customPort.port.location?.longitude ?? "",
        portStatus: customPort.port.status?.code ?? "",
        portFunctions: customPort.port.functions ?? []
    } as FormFields;

    return formFields;
};

const createPort = async (
    clientId: string,
    customerId: string,
    getFormValues: UseFormGetValues<FormFields>,
    customPortService: CustomPortService) : Promise<boolean> => {
    const formFields = getFormValues();

    let createCustomPort: CreateCustomPort;
    createCustomPort = {
        code: formFields.portCode,
        name: formFields.portAlias,
    };

    if (formFields.unLocode.length > 0) {
        let unLocode = formFields.unLocode;

        if (unLocode.length === 5) {
            unLocode = `${unLocode.slice(0, 2)} ${unLocode.slice(2)}`
        }
        createCustomPort.unloCode = unLocode;
    } else {
        createCustomPort.orphanData = {
            name: formFields.portName,
                country: {
                code: formFields.portCountry,
                    name: null
            },
            location: {
                code: formFields.portLocation,
                    longitude: formFields.portLongitude,
                    latitude: formFields.portLatitude
            },
            subdivision: {
                code: formFields.portSubdivision,
                    name: null
            },
            functions: formFields.portFunctions,
                status: {
                code: formFields.portStatus,
                    description: null
            }
        }
    }

    return await customPortService.postCustomPortAsync(customerId, clientId, createCustomPort);
}

const updatePort = async (
    clientId: string,
    customerId: string,
    customPort: CustomPort,
    getFormValues: UseFormGetValues<FormFields>,
    customPortService: CustomPortService) : Promise<boolean> => {
    const formFields = getFormValues();

    let port: UnecePort = {} as UnecePort;

    if (customPort.port.locode) {
        port.locode = customPort.port.locode;
    } else {
        port.name = formFields.portName;
        port.country = {
            code: formFields.portCountry,
            name: null
        };
        port.location = {
            code: formFields.portLocation,
            longitude: formFields.portLongitude,
            latitude: formFields.portLatitude
        };
        port.subdivision = {
            code: formFields.portSubdivision,
            name: null
        };
        port.functions = formFields.portFunctions;
        port.status = {
            code: formFields.portStatus,
            description: null
        }
    }

    const updateCustomPort: CustomPort = {
        code: formFields.portCode,
        name: formFields.portAlias,
        source: customPort.source,
        port: port
    } as CustomPort;

    return await customPortService.putCustomPortAsync(customerId, clientId, customPort.code, updateCustomPort);
}

interface ComponentProperties {
    saveButtonClicked: boolean;
    onSavedHandler: (value: boolean) => void;
    onClearedFormHandler: (value: boolean) => void;
    clientId: string;
    customerId: string;
    setRequestInProgress: (value: boolean) => void;
    unPort: UnecePort | null;
    editCustomPort: CustomPort | null;
}

export const PortDetails = (props: ComponentProperties) => {
    const customPortService = new CustomPortService();
    const {addToast} = useToastMessageService();
    const {addProgressItem, removeProgressItem} = useGlobalProgressBarService();

    const requiredValidationRule = { value: true, message: i18n.t("error.field_is_required")};
    const maxLengthValidationRule = (maxLength: number) => { return { value: maxLength, message: i18n.t("error.field_max_length")}; };

    const [portFunctions, setPortFunctions] = useState<Function[]>([]);
    const [selectedPortFunctions, setSelectedPortFunctions] = useState<Function[]>([]);
    const [portStatuses, setPortStatuses] = useState<Status[]>([]);
    const [selectedPortStatus, setSelectedPortStatus] = useState("");
    const [selectedPortStatusDescription, setSelectedPortStatusDescription] = useState("");
    const [isPortStatusDescriptionOversized, setIsPortStatusDescriptionOversized] = useState(false);
    const [isUnecePortAlias, setIsUnecePortAlias] = useState(false);
    const [isClearingForm, setIsClearingForm] = useState(false);
    const [customPort] = useState<CustomPort | null>(props.editCustomPort);
    const [formValues, setFormValues] = useState<FormFields>(getFormData(props.editCustomPort));

    const formMethods = useForm<FormFields>({defaultValues: formValues});
    const {register, reset, formState: { isDirty, errors }, getValues, setValue, trigger, setError, control} = formMethods;

    const selectedPortFunctionsValues = useWatch({
        control,
        name: "portFunctions"
    });

    const onPortStatusChanged = (e: any) => {
        const portStatusCode = e.target.value;
        setValue("portStatus", portStatusCode, {
          shouldValidate: true,
        });
        const portStatus = portStatuses.find((i) => i.code === portStatusCode);
        if (portStatus) {
            const portStatusDescription = portStatus.description ?? "";
            portStatusDescription.length > maxVisibleSelectedPortStatusDescriptionLength ?
                setIsPortStatusDescriptionOversized(true) :
                setIsPortStatusDescriptionOversized(false);

            setSelectedPortStatusDescription(portStatusDescription);
        } else {
            setSelectedPortStatusDescription("");
        }

        setSelectedPortStatus(portStatusCode);
    };
    const clearButtonClickHandler = () => {
        setIsClearingForm(true);
    };
    const clearButtonConfirmationHandler = (buttonType: string) => {
        setIsClearingForm(false);

        if (buttonType === ButtonTypes.Yes) {
            clearForm();
        }
    };
    const onBlurHandler = (event: any, fieldName: FieldPath<FormFields>) => {
        trigger(fieldName)
            .then((isValid) => {
                if (isValid) {
                    setValue(fieldName, event.target.value);
                }
            })
    };
    const onPortCodeBlurHandler = (event: any) => {
        trigger("portCode")
            .then((isValid) => {
                if (isValid) {
                    const portCode: string = event.target.value;
                    setValue("portCode", portCode);

                    if(!customPort || customPort.code.toLowerCase() !== portCode.toLowerCase()) {
                        checkPortCodeUnique(portCode)
                    }
                }
            })
    };

    const saveCustomPort = () => {
        trigger().then(async (isValid) => {
            if (isValid) {
                const portCode = getValues().portCode;
                const saveCustomPort = async() => {
                    if (!customPort) {
                        const duplicateCustomPort = await customPortService.getCustomPortAsync(props.customerId, props.clientId, portCode);
                        if (duplicateCustomPort) {
                            props.onSavedHandler(false);
                            addToast("error", i18n.t("component.port.error.not_unique_custom_port_code"));

                            return false;
                        } else {
                            const saveResult = await createPort(props.clientId, props.customerId, getValues, customPortService);
                            if (saveResult) {
                                clearForm();
                                props.onSavedHandler(true);
                            } else {
                                props.onSavedHandler(false);
                            }

                            return saveResult;
                        }
                    } else {
                        if (customPort.code !== portCode) {
                            const duplicateCustomPort = await customPortService.getCustomPortAsync(props.customerId, props.clientId, portCode);
                            if (duplicateCustomPort) {
                                props.onSavedHandler(false);
                                addToast("error", i18n.t("component.port.error.not_unique_custom_port_code"));

                                return false;
                            }
                        }

                        const saveResult = await updatePort(props.clientId, props.customerId, customPort, getValues, customPortService);
                        if (saveResult) {
                            clearForm();
                            props.onSavedHandler(true);
                        } else {
                            props.onSavedHandler(false);
                        }

                        return saveResult;
                    }
                };

                try {
                    props.setRequestInProgress(true);
                    addProgressItem();
                    await saveCustomPort().then((saveResult) => {
                        if (saveResult) {
                            addToast("success", i18n.t("service.api.request_has_been_submited_successfully"));
                        }
                    })
                } catch (error: any) {
                    addToast("error", i18n.t("service.api.request_failed") + ":\n" + error.message);
                }
                finally {
                    props.setRequestInProgress(false);
                    removeProgressItem();
                }
            } else {
                props.onSavedHandler(false);
            }
        })
    };
    const checkPortCodeUnique = (portCode: string) => {
        customPortService.getCustomPortAsync(props.customerId, props.clientId, portCode)
            .then((duplicateCustomPort) => {
                if (duplicateCustomPort) {
                    setError("portCode", { message: i18n.t("error.field_not_unique") });
                    addToast("error", i18n.t("component.port.error.not_unique_custom_port_code"));
                }
            })
    };
    const setFormFieldsValues = (formValues: FormFields) => {
        setSelectedPortStatus(formValues.portStatus);
        setSelectedPortFunctions(formValues.portFunctions);
        setFormValues({...formValues});
        setValue("portCode", formValues.portCode);
        setValue("portAlias", formValues.portAlias);
        setValue("portName", formValues.portName);
        setValue("portCountry", formValues.portCountry);
        setValue("portLocation", formValues.portLocation);
        setValue("portSubdivision", formValues.portSubdivision);
        setValue("unLocode", formValues.unLocode);
        setValue("portLongitude", formValues.portLongitude);
        setValue("portLatitude", formValues.portLatitude);
        setValue("portStatus", formValues.portStatus);
        setValue("portFunctions", formValues.portFunctions);
    };
    const clearForm = () => {
        let formValues = getDefaultFormData();
        if (customPort != null) {
            formValues = getCustomPortFormData(customPort);
        }
        setIsUnecePortAlias(false);
        props.onClearedFormHandler(true);

        setFormFieldsValues(formValues);

        reset(formValues, { keepDirtyValues: true });
    };
    const renderSelectedPortStatusText = (portStatusCode: any) => {
        const portStatus = portStatuses.find((i) => i.code === portStatusCode);

        if(!portStatus){
            return portStatusCode;
        }

        const portStatusDescription = portStatus.description ?? "";
        return portStatusDescription.toString().length > maxVisibleSelectedPortStatusDescriptionLength ?
            `${portStatusDescription.toString().substring(0, maxVisibleSelectedPortStatusDescriptionLength - 3)}...` :
            portStatusDescription;
    };

    useEffect(() => {
        if (props.unPort !== null) {
            setIsUnecePortAlias(true);
            const formValues = getUnecePortFormData(props.unPort);

            setFormFieldsValues(formValues);

            reset(formValues, { keepDirtyValues: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unPort]);

    useEffect(() => {
        if (props.saveButtonClicked) {
            saveCustomPort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.saveButtonClicked]);

    useEffect(() => {
        setSelectedPortFunctions(selectedPortFunctionsValues)
    }, [selectedPortFunctionsValues]);

    useEffect(() => {
        if (!isDirty) {
            props.onClearedFormHandler(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDirty])

    useEffect(() => {
        customPortService.getPortFunctionsAsync()
            .then((response) => {
                setPortFunctions(response);

                customPortService.getPortStatusesAsync()
                    .then((response) => {
                        setPortStatuses(response);

                        if (customPort) {
                            setSelectedPortStatus(customPort.port?.status?.code ?? "");
                            setIsUnecePortAlias(customPort.source.value === "UNECE");
                        }
                    });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={e => e.preventDefault()} style={{width: "auto"}}>
                <Typography variant="h2" component="h2" sx={{display: "flex", justifyContent: "space-between"}}>
                    {customPort === null ?
                        isUnecePortAlias ? i18n.t("component.port.add.alias") : i18n.t("component.port.add.port") :
                        customPort.port.locode ? i18n.t("component.port.edit.alias") : i18n.t("component.port.edit.port")}
                    <Tooltip title={isDirty ? i18n.t("component.port.reset_form_fields_tooltip") : ""}>
                        <span>
                            <UndoButton
                                id="ClearCustomPortFormButton"
                                disabled={!isDirty}
                                onClick={clearButtonClickHandler}
                                />
                        </span>
                    </Tooltip>
                </Typography>
                <Divider textAlign="right" sx={{mb: 2}}></Divider>
                <Box textAlign="left" sx={{display: "flex", flexDirection: "row", width: "auto"}}>
                    <CustomStyledTextField
                        {...register("portCode", {
                            required: requiredValidationRule,
                            maxLength: maxLengthValidationRule(100)
                        })}
                        label={i18n.t("component.port.properties.code")}
                        maxlength={100}
                        onBlur={onPortCodeBlurHandler}
                        error={Boolean(errors.portCode)}
                        helperText={errors.portCode && errors.portCode.message}
                    />

                    <CustomStyledTextField
                        {...register("portAlias", {
                            required: requiredValidationRule,
                            maxLength: maxLengthValidationRule(50)
                        })}
                        label={i18n.t("component.port.properties.alias")}
                        maxlength={50}
                        onBlur={(e: any) => {onBlurHandler(e, "portAlias")}}
                        error={Boolean(errors.portAlias)}
                        helperText={errors.portAlias && errors.portAlias.message}
                    />

                    <CustomStyledTextField
                        {...register("portName", {
                            disabled: isUnecePortAlias,
                            required: requiredValidationRule
                        })}
                        label={i18n.t("component.port.properties.name")}
                        maxlength={50}
                        onBlur={(e: any) => {onBlurHandler(e, "portName")}}
                        error={Boolean(errors.portName)}
                        helperText={errors.portName && errors.portName.message}
                    />

                    <CustomStyledTextField
                        {...register("portCountry", {
                            required: requiredValidationRule,
                            maxLength: maxLengthValidationRule(2)
                        })}
                        label={i18n.t("component.port.properties.country")}
                        disabled={isUnecePortAlias}
                        maxlength={2}
                        onBlur={(e: any) => {onBlurHandler(e, "portCountry")}}
                        error={Boolean(errors.portCountry)}
                        helperText={errors.portCountry && errors.portCountry.message}
                    />

                    <CustomStyledTextField
                        {...register("portLocation", {
                            maxLength: maxLengthValidationRule(5)
                        })}
                        label={i18n.t("component.port.properties.location")}
                        disabled={isUnecePortAlias}
                        maxlength={5}
                        error={Boolean(errors.portLocation)}
                        helperText={errors.portLocation && errors.portLocation.message}
                    />

                    <CustomStyledTextField
                        {...register("portSubdivision", {
                            maxLength: maxLengthValidationRule(4)
                        })}
                        label={i18n.t("component.port.properties.subdivision")}
                        disabled={isUnecePortAlias}
                        maxlength={4}
                        error={Boolean(errors.portSubdivision)}
                        helperText={errors.portSubdivision && errors.portSubdivision.message}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        direction: "row",
                        width: "auto"
                    }}>
                    <Box sx={{width: "50%", marginTop: "10px"}}>
                        <Box sx={{display: "flex", flexDirection: "row", width: "auto"}}>
                            <CustomStyledTextField
                                {...register("unLocode")}
                                disabled={true}
                                label={i18n.t("component.port.properties.unlocode")}
                            />

                            <CustomStyledTextField
                                {...register("portLongitude", {
                                    disabled: isUnecePortAlias,
                                    required: requiredValidationRule,
                                    maxLength: maxLengthValidationRule(9)
                                })}
                                label={i18n.t("component.port.properties.longitude")}
                                maxlength={9}
                                onBlur={(e: any) => {onBlurHandler(e, "portLongitude")}}
                                error={Boolean(errors.portLongitude)}
                                helperText={errors.portLongitude && errors.portLongitude.message}
                            />

                            <CustomStyledTextField
                                {...register("portLatitude", {
                                    disabled: isUnecePortAlias,
                                    required: requiredValidationRule,
                                    maxLength: maxLengthValidationRule(9)
                                })}
                                label={i18n.t("component.port.properties.latitude")}
                                maxlength={9}
                                onBlur={(e: any) => {onBlurHandler(e, "portLatitude")}}
                                error={Boolean(errors.portLatitude)}
                                helperText={errors.portLatitude && errors.portLatitude.message}
                            />
                        </Box>

                        <Box sx={{
                            marginTop: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px"
                        }}>
                            <InputLabel
                                sx={{color: textColor, fontSize: labelFontSize, left: "6px"}}>
                                {i18n.t("component.port.properties.status")}
                            </InputLabel>
                            <CustomWidthTooltip
                                title={isPortStatusDescriptionOversized ? selectedPortStatusDescription : ""}
                                placement="bottom-start">
                                <SelectCustom
                                    {...register("portStatus", {
                                        disabled: isUnecePortAlias,
                                        required: requiredValidationRule
                                    })}
                                    sx={{height: "35px", width: "100%", marginTop: "8px"}}
                                    value={selectedPortStatus}
                                    onBlur={(e: any) => trigger("portStatus")}
                                    onChange={onPortStatusChanged}
                                    renderValue={renderSelectedPortStatusText}
                                    error={Boolean(errors.portStatus)}
                                >
                                    {portStatuses.map((status) => (
                                        <MenuItem value={status.code} key={status.code}>
                                            {status.description}
                                        </MenuItem>
                                    ))}
                                </SelectCustom>
                            </CustomWidthTooltip>
                            {errors.portStatus && <FormHelperText className="Mui-error">{errors.portStatus.message}</FormHelperText>}
                        </Box>

                    </Box>
                    <Box sx={{width: "50%", marginTop: "10px", paddingLeft: "5px"}}>
                        <InputLabel
                            sx={{color: textColor, fontSize: labelFontSize, left: "6px"}}>
                            {i18n.t("component.port.properties.functions")}
                        </InputLabel>
                        <Box sx={{width: "auto", marginTop: "8px", paddingRight: "5px"}}>
                            <PortFunctions
                                portFunctions={portFunctions}
                                selectedPortFunctions={selectedPortFunctions}
                                isUnecePortAlias={isUnecePortAlias}/>
                        </Box>
                    </Box>
                </Box>
            </form>
            <OkCancelModal
                isOpen={isClearingForm}
                onButtonClick={clearButtonConfirmationHandler}
                title="Warning"
                message={i18n.t("component.port.message.clean_form_warning")}
            />
        </FormProvider>
    );
};

export default PortDetails;
