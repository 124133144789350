import { localStorageConst } from "../../constants/global.constants";

export class UserUtility {
  public static GetUserId(): string | null {
    let userId = localStorage.getItem(localStorageConst.USER_ID);
    return userId ?? "undefined";
  }

  public static SetUserId(userId: string): void {
    localStorage.setItem(localStorageConst.USER_ID, userId ?? "");
  }
}