import { Select, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export const languages = [
	{ code: "en", name: "English" },
	{ code: "es", name: "Spanish" },
    { code: "entest", name: "EnglishTest" }
];

export const LanguageSelect = () => {
  const { t, i18n } = useTranslation();

  return (
    <Select autoWidth={true} defaultValue={"en"}>
      {languages.map((lang) => (
        <MenuItem
          onClick={() => i18n.changeLanguage(lang.code)}
          value={lang.code}
          key={lang.code}
        >
          {t("component.language."+lang.code)}
        </MenuItem>
      ))}
    </Select>
  );
};