import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ButtonTypes } from "../../models/shared/button/buttonTypes";
import { theme } from "../../../styles/theme";
import { PrimaryButton } from "../styled/button/styledButtonPrimary.component";
import { SecondaryButton } from "../styled/button/styledButtonSecondary.component";

const textColor = theme.palette.text.primary;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  backgroundColor: theme.background.page,
  border: "0",
  borderRadius: "6px",
  boxShadow: 24,
  color: textColor,
  p: 1
};

interface okCancelModalProps {
  isOpen: boolean;
  onButtonClick: (buttonType: string) => void;
  title: string;
  message: string;
}

export function OkCancelModal({
  isOpen,
  onButtonClick,
  title,
  message
}: okCancelModalProps) {

  const { t } = useTranslation();

  return (
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {title.length > 0 && <Box sx={{ textAlign: "center", mb: 1}}>{title}</Box>}
          <Typography
              id="modal-modal-title"
              sx={{
                display: "block",
                width: "100%",
                height: "80%",
                color: textColor,
                borderRadius: "6px",
                whiteSpace: "pre-line",
                p: 2,
                backgroundColor: theme.background.component
              }}>
            {message}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "right", mt: 1 }}>
            <SecondaryButton
                sx={{
                  backgroundColor: theme.palette.primary.light,
                  color: theme.colors.blueGray,
                }}
                onClick={() => onButtonClick(ButtonTypes.Yes)}>{t("component.action.ok")}</SecondaryButton>
            <PrimaryButton onClick={() => onButtonClick(ButtonTypes.No)}>{t("component.action.cancel")}</PrimaryButton>
          </Box>
        </Box>
      </Modal>
  );
}

export {};