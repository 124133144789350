import { useEffect, useState } from 'react';
import { YesModal } from '../modal/yesModal.component';
import { ButtonActionTypes } from '../../models/shared/button/buttonActionTypes';
import { ButtonTypes } from '../../models/shared/button/buttonTypes';
import { useTranslation } from "react-i18next";
import { msalInstance } from '../../../auth';
import { useIdleTimer } from 'react-idle-timer';

const InactivityLogoutTimer = () => {

    const { t } = useTranslation();

    const seconds = 30;
    const thousandMilliseconds = 1000;

    const promptBeforeIdle = seconds * thousandMilliseconds;
    const timeout = 1 * 60 * 60 * thousandMilliseconds + promptBeforeIdle;
    
    const [remaining, setRemaining] = useState<number>(timeout)

    const [isModalOpen, setIsModalOpen] = useState(false);


    const onIdle = () => {
      setIsModalOpen(false);
      logoutWithoutPrompt();
    };

    const onPrompt = () => {
      setIsModalOpen(true);
    };    

    const handleModalButtonClick = (actionType: string, buttonType: string) => {
      if (actionType === ButtonActionTypes.LeavePage) {
        if (buttonType === ButtonTypes.Yes) {
          setIsModalOpen(false);
          activate();
        }
        else {
            pause();
            logoutWithoutPrompt();
        }
      }
    };

    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / thousandMilliseconds));
      }, thousandMilliseconds);

      return () => {
        clearInterval(interval);
      };
    });

    const logoutWithoutPrompt = () => {
        const account = msalInstance.getActiveAccount();
        msalInstance.logoutRedirect({ account: account });
    };

    const { getRemainingTime, activate, pause } = useIdleTimer({
        onIdle,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: thousandMilliseconds,
        events: ['keydown', 'mousedown'], // Only watch for keydown and mousedown events
    });

    return (
      <YesModal
        isOpen={isModalOpen}
        actionType={ButtonActionTypes.LeavePage}
        onButtonClick={handleModalButtonClick}
        message={t("component.inactivity.logout_question", {seconds})}
        timer={remaining}
      />
    ); 
};

export default InactivityLogoutTimer;