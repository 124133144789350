import { Box, Typography } from "@mui/material";
import { useSelectedCustomer } from "../../context/customer/customerSelection.Context";
import { useTranslation } from "react-i18next";
import { theme } from "../../../styles/theme";
import {
  BrowserType,
  detectBrowser,
} from "../../../utils/browser/DetectBrowser";
import { useEffect, useState } from "react";

export const SelectedCustomer = () => {
  const { selectedCustomer } = useSelectedCustomer();
  const [browser, setBrowser] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  return (
    <Box sx={{ whiteSpace: "nowrap" }}>
      <Typography
        sx={{
          [theme.breakpoints.down("xl")]: {
            fontSize: browser === BrowserType.CHROME ? "medium" : "auto",
          },
          [theme.breakpoints.down("lg")]: {
            fontSize: browser === BrowserType.CHROME ? "small" : "medium",
          },
          [theme.breakpoints.down("mdPlus")]: {
            fontSize: browser === BrowserType.CHROME ? "x-small" : "small",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: browser === BrowserType.CHROME ? "xx-small" : "x-small",
          },
        }}
      >
        {t("component.header.selected_customer")} {selectedCustomer?.name}
      </Typography>
    </Box>
  );
};
