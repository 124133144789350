import { localStorageConst } from "../../constants/global.constants";
import { v4 as uuidv4 } from "uuid";

export class SessionUtility {
  public static GetSessionId(): string | null {
    let sessionId = localStorage.getItem(localStorageConst.SESSION_ID);
    return sessionId ?? "undefined";
  }

  public static SetSessionId(): void {
    localStorage.setItem(localStorageConst.SESSION_ID, uuidv4());
  }
}