import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { ButtonTypes } from "../../models/shared/button/buttonTypes";
import { theme } from "../../../styles/theme";
import { PrimaryButton } from "../styled/button/styledButtonPrimary.component";
import { SecondaryButton } from "../styled/button/styledButtonSecondary.component";

const backgroundColor = "#141D27";
const textColor = theme.palette.text.primary;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: backgroundColor,
  border: "2px solid #000",
  boxShadow: 24,
  color: textColor,
  p: 4,
};

interface YesNoModalProps {
  isOpen: boolean;
  actionType: string;
  onButtonClick: (actionType: string, buttonType: string) => void;
  message: string;
}

export function YesNoModal({
  isOpen,
  actionType,
  onButtonClick,
  message
}: YesNoModalProps) {

  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color: textColor}}>
            {message}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <PrimaryButton onClick={() => onButtonClick(actionType, ButtonTypes.Yes)}>{t("routes.general.yes")}</PrimaryButton>
            <SecondaryButton onClick={() => onButtonClick(actionType, ButtonTypes.No)}>{t("routes.general.no")}</SecondaryButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export {};