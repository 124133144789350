import { Alert, Box, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useToastMessageService } from "../../context/toast/toastMessage.Context";

export const ToastNotifications = () => {
  const { toasts, removeToast } = useToastMessageService();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (toasts.length > 0) {
      setOpen(true);
    }
  }, [toasts]);

  return (
    <>
      <Box key="toast-messages">
        {toasts.map((toast, index) => (
          <Snackbar
            key={toast.id}
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={null}
            style={{ marginBottom: "10px", bottom: `${index * 60}px` }}
          >
            <Alert
              severity={toast.severity}
              sx={{ width: "300px" }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="medium"
                  onClick={() => removeToast(toast.id)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {toast.title}
              {toast.messages && (
                <ul>
                  {toast.messages.map((message, index) => (
                    <li key={index}>{message}</li>
                  ))}
                </ul>
              )}
            </Alert>
          </Snackbar>
        ))}
      </Box>
    </>
  );
};
