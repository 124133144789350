import { useEffect, useRef, useState } from 'react';
import { ClientService } from '../../../service/api/client/clientService';
import { SyncService } from '../../../service/api/sync/syncService';
import { useSelectedClientApp } from "../../context/clientApplication/clientApplicationSelection.context";
import { ClientUtility } from "../../../utils/data/client.utility";
import { useToastMessageService } from "../../context/toast/toastMessage.Context";
import i18n from 'i18next';

export const SynchronizationData = () => {
  
    const thousandMilliseconds = 1000;
    const timeout = 1 * 60 * 60 * thousandMilliseconds;
  
    const [remaining, setRemaining] = useState<number>(timeout);
    const { selectedClientApp, loading } = useSelectedClientApp();
    const isInitialMount = useRef(true);

    const clientService = new ClientService();
    const syncService = new SyncService();

    const { addToast } = useToastMessageService();

    const callGetClientLastSyncDateTimeService = async () => {
      try {
        const selectedClientId = ClientUtility.GetSelectedClientId();

        const lastSyncDateTime = await clientService.getClientLastSyncDateTimeService(selectedClientId ?? "");

        const currentDate = new Date(new Date().toUTCString());
        const differenceInMilliseconds = currentDate.getTime() - lastSyncDateTime.getTime();

        if (differenceInMilliseconds > timeout) {
          callSyncService();
          setRemaining(timeout);
        } else {
          setRemaining(timeout - differenceInMilliseconds);
        }
      } catch (error) {
        setRemaining(timeout);
        addToast("error", i18n.t("service.sync.error_getting_client_last_sync_date"));
      }
    };

    const callSyncService = async () => {
      try {
        const selectedClientId = ClientUtility.GetSelectedClientId();

        const { status } = await syncService.getSyncClientService(selectedClientId);

        if (status !== 200) {
          addToast("error", i18n.t("service.sync.error_calling_the_function_to_synchronize_client_data"));
        }
      }
      catch (error) {
        addToast("error", i18n.t("service.sync.error_calling_the_function_to_synchronize_client_data"));
      }
    }

    useEffect(() => {
      const fetchData = async () => {
        await callGetClientLastSyncDateTimeService();
      };

      if (!loading) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClientApp]);

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }

      const fetchData = async () => {
        await callGetClientLastSyncDateTimeService();
      };
      const intervalId = setInterval(fetchData, remaining);
  
      return () => clearInterval(intervalId);
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remaining]);

    useEffect(() => {

      const handleLoginSuccess = async (event: CustomEvent) => {
        try {
          const isClientMDF = await clientService.isClientMDFService(ClientUtility.GetSelectedClientId() ?? "");

          if (isClientMDF) {
            callSyncService();
            setRemaining(timeout);
          }
        }
        catch (error) {
          setRemaining(timeout);
          addToast("error", i18n.t("service.sync.error_calling_the_function_to_synchronize_client_data"));
        }
      };

      window.addEventListener('custom:loginSuccess', handleLoginSuccess as unknown as EventListener);

      return () => {
        window.removeEventListener('custom:loginSuccess', handleLoginSuccess as unknown as EventListener);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
      </>
    ); 
};