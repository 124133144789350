import { Box } from "@mui/material";
import { VesselFileUpload } from "../../shared/components/vessel/uploadVessel.component";
import { StyledHeader } from "../../shared/components/styled/header/styledHeader.component";
import DataGridVessel from "../../shared/components/vessel/grid/dataGridVessel.component";
import { useSignalRService } from "../../shared/context/signalR/signalR.Context";
import { useEffect, useRef, useState } from "react";
import { SignalRTargets } from "../../shared/models/shared/signalR/enum/signalRTargets";
import { ClientUtility } from "../../utils/data/client.utility";
import { useToastMessageService } from "../../shared/context/toast/toastMessage.Context";
import { Vessel } from "../../shared/models/shared/vessel/vessel.model";
import { CustomerUtility } from "../../utils/data/customer.utility";
import { useTranslation } from "react-i18next";

export function VesselRoute() {
  const { signalRData } = useSignalRService();
  const isInitialRender = useRef(true);
  const { addToast } = useToastMessageService();
  const [processedVessel, setProcessedVessel] = useState<Vessel | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    if (!NotificationMessagesShouldBeProcessed) return;

    ProcessVesselProcessingDLQ();
    ProcessVesselProcessingResposne();

    // eslint-disable-next-line
  }, [signalRData]);

  function ProcessVesselProcessingDLQ() {
    if (
      signalRData?.Target === SignalRTargets.VesselDlqBlobTargetName ||
      signalRData?.Target === SignalRTargets.VesselDlqParserTargetName ||
      signalRData?.Target === SignalRTargets.VesselDlqPersistTargetName
    ) {
      addToast(
        "error",
        t("routes.vessels.notification.error.vessel_processing"),
        signalRData?.Data.errors
      );
    }
  }

  function ProcessVesselProcessingResposne() {
    if (signalRData?.Target === SignalRTargets.VesselQueueResponseTargetName) {
      if (signalRData?.Data.errors.length === 0) {
        setProcessedVessel(signalRData?.Data.vessel);
        addToast(
          "info",
          t("routes.vessels.notification.info.vessel_added_created")
        );
      } else {
        addToast(
          "error",
          t("routes.vessels.notification.error.vessel_processing"),
          signalRData?.Data.errors
        );
      }
    }
  }

  function NotificationMessagesShouldBeProcessed(): boolean {
    return (
      ClientUtility.IsSelectedClientApp(signalRData?.Data.clientId) &&
      CustomerUtility.IsSelectedCustomer(signalRData?.Data.customerId)
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <VesselFileUpload />
      <StyledHeader key="customer_vessels" label="Customer Vessels" />
      <DataGridVessel item={processedVessel} />
    </Box>
  );
}
