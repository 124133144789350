import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { theme } from "../../../../styles/theme";

const textColor = theme.palette.text.primary;
const backgroundColor = theme.palette.primary.main;

export const PrimaryButton = styled(Button)(() => {
  return {
    borderRadius: "8px",
    color: textColor,
    backgroundColor: backgroundColor,
    fontSize: "16px",
    height: "40px",
    padding: "1px 25px 1px 25px",
    textTransform: "none",
    width: "auto",
    "&.Mui-disabled": {
      color: "white",
    },
  };
});
