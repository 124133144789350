import {Box, IconButton, styled} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridSortItem
} from "@mui/x-data-grid-pro";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {theme} from "../../../../../styles/theme";
import {Port} from "../../../../models/port/port.model";
import {dataGridPageSize, DataRequest} from "./searchUnecePorts.component";
import {UnecePort} from "../../../../models/port/protoPort.model";
import {YesModal} from "../../../modal/yesModal.component";

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <UnfoldMoreOutlinedIcon />;
}

const getGridColumns = (t: Function, handleCloneClick: Function):GridColDef[] => {
  return [
    {
      field: "unLocode",
      headerName: t("component.port.grid.column.unLocode"),
      flex: 1.5
    }, {
      field: "country",
      headerName: t("component.port.grid.column.country"),
      flex: 1.3
    }, {
      field: "name",
      headerName: t("component.port.grid.column.name"),
      flex: 2
    }, {
      field: "lastChangedDate",
      headerName: t("component.port.grid.column.lastChangedDate"),
      flex: 2.2
    }, {
      field: 'actions',
      type: 'actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<ContentCopyIcon />}
            label={t("component.action.edit")}
            className="textPrimary"
            onClick={handleCloneClick(id)}
            color="inherit"
          />
        ];
      }
    }
  ]
};

const getSelectedPortWarningMessage = (selectedPort: Port | undefined | null, t: Function): string => {

  if (!selectedPort) {
    return `${t("component.port.message.selected_port_invalid")}.`;
  }

  const messages = [`${t("component.port.message.selected_port_invalid")}: \n`];
  const isCountryMissing = selectedPort.country.length === 0;
  const isUnLocodeMissing = selectedPort.unLocode.length === 0;

  if (isCountryMissing) {
    messages.push(t("component.port.message.selected_port_country_required"));
  }

  if (isUnLocodeMissing) {
    if (isCountryMissing) {
      messages.push(". \n");
    }
    messages.push(`${t("component.port.message.selected_port_unlocode_required")}`);
  }

  messages.push(".");

  return messages.join("");
}

const StyledDataGrid = styled(DataGridPro)(() => ({
  "& .MuiDataGrid-root": {
    borderStyle: "none",
    borderWidth: "0",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderStyle: "none",
    borderWidth: "0",
  },
  "& .MuiDataGrid-main": {
    height: "283px",
  },
  "& .MuiDataGrid-virtualScroller": {
    scroll: "auto",
  },
  "& .MuiDataGrid-row:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "& .MuiDataGrid-cell": {
    border: "transparent",
  },
  "& .MuiDataGrid-container--top [role=row]": {
    backgroundColor: theme.background.page,
    fontSize: "14px",
  },
  "& .MuiDataGrid-withBorderColor": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-container--top::after": {
    backgroundColor: "transparent",
  },
  "& .MuiDataGrid-overlayWrapper": {
    height: "30px",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    overflow: "auto"
  },
  "& .MuiDataGrid-columnHeaderTitleContainerContent": {
    overflow: "auto"
  }
}));

const sortColumnNamesMappings = new Map<string, string>([
    ["unLocode", "locode"],
    ["lastChangedDate", "date"],
    ["country", "country"]
]);

const defaultSorting: GridSortItem = {
  field: "unLocode",
  sort: "asc",
} as GridSortItem

interface ComponentProperties {
  data: Port[];
  dataSource: UnecePort[];
  initDataRequest: (dataRequest: DataRequest) => void
  isNewSearch: boolean;
  isDataLoading: boolean;
  setSelectedUNPort: (value: UnecePort) => void
}

export const DataGridPort = (props: ComponentProperties) => {
  const {t} = useTranslation();

  const [filter] = useState("");
  const [sortModel, setSortModel] = useState<GridSortItem[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [lastPageNumber, setLastPageNumber] = useState<number | null>(null);
  const [warningOpen, setWarningOpen] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<string>("");

  const onSortModelChangeHandler = (sortModel: GridSortItem[]) => {
    if (sortModel.length > 0) {
      props.initDataRequest({
        sort: sortColumnNamesMappings.get(sortModel[0].field) ?? sortModel[0].field,
        sortOrder: sortModel[0].sort?.substring(0, 1) ?? "",
        pageNumber: pageNumber,
        pageSize: dataGridPageSize
      } as DataRequest);
      setSortModel(sortModel);
    } else {
      props.initDataRequest({
        sort: sortColumnNamesMappings.get("unLocode"),
        sortOrder: "a",
        pageNumber: pageNumber,
        pageSize: dataGridPageSize
      } as DataRequest);
      setSortModel([defaultSorting]);
    }
  }
  const getPageData = (pageNumber: number) => {
      if(pageNumber < 0) {
        pageNumber = 0;
      }
      setPageNumber(pageNumber);
      props.initDataRequest({
        sort: sortColumnNamesMappings.get(sortModel[0].field) ?? sortModel[0].field,
        sortOrder: sortModel[0].sort?.substring(0, 1) ?? "",
        pageNumber: pageNumber,
        pageSize: dataGridPageSize
      } as DataRequest);
  }
  const handleCloneClick = (id: GridRowId) => () => {
    if (id.toString().indexOf("UIID_") === 0) {
      const selectedPort = props.data.find((p) => p.uiId === id);
      setWarningOpen(true);
      const message = getSelectedPortWarningMessage(selectedPort, t);
      setWarningMessage(message);
    } else {
      const selectedUNPort = props.dataSource.find((p) => p.locode === id);
      if (selectedUNPort !== undefined) {
        props.setSelectedUNPort({...selectedUNPort});
      }
    }
  };
  const closeWarning = () => {
    setWarningOpen(false);
    setWarningMessage("");
  }

  useEffect(() => {
    if (props.isNewSearch) {
      setSortModel([defaultSorting]);
      setPageNumber(0);
      setLastPageNumber(null);
    }

    if (sortModel.length === 0 && props.data.length > 0) {
      setSortModel([defaultSorting]);
    }

    if (props.data.length < dataGridPageSize) {
      setLastPageNumber(pageNumber);
    }

    if (props.data.length === 0) {
      setPageNumber(0);
      setLastPageNumber(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  const filteredData = props.data.filter(
    (row) =>
      row.name.toLowerCase().includes(filter.toLowerCase()) ||
      row.code.toLowerCase().includes(filter.toLowerCase()) ||
      row.country.toLowerCase().includes(filter.toLowerCase()) ||
      row.alias.toLowerCase().includes(filter.toLowerCase()) ||
      row.lastChangedDate
        .toString()
        .toLowerCase()
        .includes(filter.toLowerCase())
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: theme.background.page,
          marginTop: "5px",
          height: "283px"
        }}
      >
        <Box>
          <StyledDataGrid
            sx={{
              border: "none",
              BorderStyle: "none !important",
              rowBorderColor: "red"
            }}
            key='unece-ports-search-result'
            getRowId={(row) => row.unLocode.length > 0 ? row.unLocode : row.uiId}
            rows={filteredData}
            columns={getGridColumns(t, handleCloneClick)}
            disableRowSelectionOnClick
            hideFooter
            disableColumnMenu
            disableDensitySelector
            sortModel={sortModel}
            onSortModelChange={onSortModelChangeHandler}
            filterMode="server"
            loading={props.isDataLoading}
            slots={{
              columnSortedDescendingIcon: SortedDescendingIcon,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnUnsortedIcon: UnsortedIcon,
            }}
          />
        </Box>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <IconButton sx={{
          color: theme.palette.secondary.contrastText,
          padding: "2px",
          marginRight: "3px"
        }}
          id="prevPageButton"
          onClick={() => { getPageData(pageNumber - 1); }}
          disabled={pageNumber === 0}
        >
          <NavigateBeforeIcon />
        </IconButton>
        <IconButton
            sx={{
              color: theme.palette.secondary.contrastText,
              padding: "2px"
            }}
            id="nextPageButton"
            onClick={() => { getPageData(pageNumber + 1); }}
            disabled={pageNumber === lastPageNumber}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
      <YesModal
          isOpen={warningOpen}
          onButtonClick={closeWarning}
          message={warningMessage}
          actionType=""
          timer={undefined} />
    </>
  );
}