import React, {
    useState,
    useEffect
} from "react";
import { 
  Box,
  Button,
  Modal,
  Stack,
  Tooltip
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { theme } from "../../../../styles/theme";
import PortDetails from "./portDetails.component";
import SearchUnecePortsComponent from "./searchUnecePorts/searchUnecePorts.component";
import {useSelectedClientApp} from "../../../context/clientApplication/clientApplicationSelection.context";
import {useSelectedCustomer} from "../../../context/customer/customerSelection.Context";
import i18n from "i18next";
import {StyledHeader} from "../../styled/header/styledHeader.component";
import {UnecePort, CustomPort} from "../../../models/port/protoPort.model";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '60%',
  maxWidth: '90%',
  backgroundColor: theme.background.component,
  borderColor: theme.background.component,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "6px",
  display: "inline-block",
  padding: "6px",
  maxHeight: "95%",
  overflowY: "auto",
  overflowX: "auto",
};

interface ComponentProperties {
    onPortCreatedHandler: (value: boolean) => void;
    editCustomPort: CustomPort | null;
    open: boolean;
    onClose: () => void;
}

export const PortDetailsModal = (props: ComponentProperties) => {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const [saveButtonClicked, setSaveButtonClicked] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState(false);
    const [selectedUNPort, setSelectedUNPort] = useState<UnecePort | null>(null);
    const [isPortDetailsFormCleared, setIsPortDetailsFormCleared] = useState(false);

    const onCloseHandler = () => {
        setIsPortDetailsFormCleared(false);
        setOpen(false);
        props.onClose();
    };
    const dataSavedHandler = (savedResult: boolean) => {
        setSaveButtonClicked(false);
        if (savedResult) {
            setSelectedUNPort(null);
            props.onPortCreatedHandler(savedResult);
            props.onClose();
        }
        setOpen(!savedResult)
    };

    const isEditing = () => {
        return props.editCustomPort != null;
    }

    let selectedClientId = useSelectedClientApp().selectedClientApp?.clientApp?.id ?? "";
    let selectedCustomerId = useSelectedCustomer().selectedCustomer?.id ?? "";

    useEffect(() => {
        if (!props.open) {
          setSaveButtonClicked(false);
          setSelectedUNPort(null);
        }

        setOpen(props.open);
    }, [props.open]);

    useEffect(() => {
        setOpen(false);
        setSelectedUNPort(null);
    }, [selectedClientId, selectedCustomerId]);

    useEffect(() => {
        if (props.editCustomPort) {
            setOpen(true);
        }
    }, [props.editCustomPort]);

    return (
      <Modal
        open={open}
        onClose={onCloseHandler}
      >
        <Box sx={modalStyle}>
            <Box sx={{
                width: "auto",
                display: "inline-block",
                backgroundColor: theme.background.componentLight,
                borderColor: theme.background.componentLight,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "6px",
                padding: "15px 6px 6px 6px"
            }}>
                <Box sx={{ width: "auto", paddingBottom: "15px" }}>
                    <PortDetails
                      unPort={selectedUNPort}
                      saveButtonClicked={saveButtonClicked}
                      clientId={selectedClientId}
                      customerId={selectedCustomerId}
                      onSavedHandler={dataSavedHandler}
                      onClearedFormHandler={setIsPortDetailsFormCleared}
                      setRequestInProgress={setRequestInProgress}
                      editCustomPort={props.editCustomPort}
                    />
                </Box>
                {!isEditing() &&
                    <>
                        <StyledHeader label={i18n.t("component.port.add.search_unport_title")}/>
                        <Box sx={{ width: "auto", display: "flex", justifyContent: "center" }}>
                            <Box sx={{width: "70%", maxWidth: "90%"}}>
                                <SearchUnecePortsComponent
                                    reset={isPortDetailsFormCleared}
                                    setSelectedUNPort={setSelectedUNPort} />
                            </Box>
                        </Box>
                    </>
                }
            </Box>
            <Stack
                alignItems="center"
                justifyContent="flex-end"
                direction="row"
                spacing={1}
                sx={{
                    marginTop: "6px",
                }}
            >
                <Button
                    sx={{
                        backgroundColor: theme.palette.primary.light,
                        color: theme.colors.blueGray,
                    }}
                    id="cancelPortDetailsButton"
                    onClick={onCloseHandler}
                >
                    {t("component.action.cancel")}
                </Button>
                <Tooltip title={requestInProgress ? t("service.api.another_request_in_progress") : ""}>
                    <span>
                        <Button
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.secondary.contrastText,
                            }}
                            id="addPortDetailsButton"
                            onClick={() => { setSaveButtonClicked(true); }}
                            disabled={requestInProgress}
                        >
                            {t("component.action.save")}
                        </Button>
                    </span>
                </Tooltip>
            </Stack>
        </Box>
      </Modal>
  );
};

export default PortDetailsModal;