import { useDropzone } from "react-dropzone";
import { Box, Stack } from "@mui/material";
import { theme } from "../../../styles/theme";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PrimaryButton } from "../styled/button/styledButtonPrimary.component";
import { VesselService } from "../../../service/api/vessel/vesselService";
import { useGlobalProgressBarService } from "../../context/globalProgressBar/globalProgressBar.Context";
import { useToastMessageService } from "../../context/toast/toastMessage.Context";
import { useEffect, useState } from "react";
import { VesselProcessingAction } from "../../models/vessel/vesselProcessingAction.enum";
import { useSelectedClientApp } from "../../context/clientApplication/clientApplicationSelection.context";
import { useSelectedCustomer } from "../../context/customer/customerSelection.Context";
import { useTranslation } from "react-i18next";

export const VesselFileUpload = () => {
  const vesselService = new VesselService();
  const { addProgressItem, removeProgressItem } = useGlobalProgressBarService();
  const { addToast } = useToastMessageService();
  const { selectedClientApp } = useSelectedClientApp();
  const { selectedCustomer } = useSelectedCustomer();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [action, setAction] = useState<VesselProcessingAction>(
    VesselProcessingAction.Unknown
  );
  const [disableChooseFile, setDisableChooseFile] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setDisableChooseFile(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientApp]);

  useEffect(() => {
    if (selectedClientApp && selectedCustomer?.id) {
      setDisableChooseFile(false);
    } else {
      setDisableChooseFile(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xlsm'],
    },
    onDropRejected: (event) => {
      setSelectedFile(null);
      addToast("error", t("routes.vessels.invalid_file_format") + "." + t("routes.vessels.please_upload_an_excel_file") + "!");
    },
    onDropAccepted: (event) => {
      setSelectedFile(event[0]);
    }
  });

  const handleVesselFileProcessing = async (event: any) => {
    event.preventDefault();
    if (!selectedFile) {
      addToast("error", t("routes.vessels.no_file_selected"));
      return;
    }

    try {
      addProgressItem();

      let response = await vesselService.uploadVesselFile(
        selectedFile,
        selectedCustomer?.id ?? "",
        selectedClientApp?.clientApp?.id ?? "",
        action
      );

      if (response.success.length > 0) {
        addToast("success", t(response.success[0]));
      } else {
        addToast("error", t("routes.vessels.error_submitting_vessel_data"), response.errors);
      }
    } finally {
      removeProgressItem();
      setSelectedFile(null);
    }
  };

  return (
    <Box sx={{ width: "100%", textTransform: "none" }}>
      <form onSubmit={handleVesselFileProcessing}>
        <Box
          key="vessel-upload"
          {...getRootProps()}
          sx={{
            border: "2px dashed grey",
            borderRadius: "8px",
            textAlign: "center",
            alignItems: "center",
            padding: "50px",
            cursor: disableChooseFile ? "not-allowed" : "pointer",
            width: "100%",
            backgroundColor: isDragActive
              ? "#e0e0e0"
              : theme.background.componentLight,
            opacity: disableChooseFile ? 0.5 : 1,
            pointerEvents: disableChooseFile ? "none" : "auto",
          }}
        >
          <input {...getInputProps()} disabled={disableChooseFile}/>
          <PrimaryButton
            variant="contained"
            sx={{ width: "20%" }}
            disabled={disableChooseFile}
          >
            {t("routes.vessels.choose_file")}
          </PrimaryButton>
          <br />
          <label>{selectedFile?.name || ""}</label>
        </Box>
        <Box
          key="vessel-upload-action"
          display="flex"
          flex="space-between"
          justifyContent="right"
          margin="5px"
        >
          <Stack spacing={2} direction="row">
            <PrimaryButton
              type="submit"
              variant="contained"
              onClick={() => setAction(VesselProcessingAction.Upload)}
              endIcon={<OpenInNewIcon />}
              disabled={selectedFile == null}
            >
              {t("routes.vessels.upload_vessels_file")} (
              {selectedFile != null ? 1 : 0})
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              variant="contained"
              onClick={() => setAction(VesselProcessingAction.Update)}
              endIcon={<OpenInNewIcon />}
              disabled={selectedFile == null}
            >
              {t("routes.vessels.update_vessels")} (
              {selectedFile != null ? 1 : 0})
            </PrimaryButton>
          </Stack>
        </Box>
      </form>
    </Box>
  );
};

export default VesselFileUpload;
