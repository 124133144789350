import { localStorageConst } from "../../constants/global.constants";

export class CustomerUtility {
  public static GetSelectedCustomerId(): string | null {
    let savedCustomer = localStorage.getItem(
      localStorageConst.SELECTED_CUSTOMER
    );
    return savedCustomer ? JSON.parse(savedCustomer).id : null;
  }

  public static IsSelectedCustomer(customerId: string): boolean {
    return CustomerUtility.GetSelectedCustomerId() === customerId;
  }
}
