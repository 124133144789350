import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { theme } from '../../../../styles/theme';

const hoverColor = theme.palette.action.hover;
const focusedColor = theme.palette.action.focus;

export const SecondaryButton = styled(Button)(() => {
  return {
    backgroundColor: 'transparent',
    color: theme.alerts.ctaColor,
    borderRadius: '6px',
    borderWidth: '1.4px',
    borderColor: theme.alerts.ctaColor,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.016px',
    height: '40px',
    textAlign: 'center',
    margin: '0 12px',
    padding: '1px 0',
    textTransform: 'none',
    width: "82px",
    '& .MuiSvgIcon-root': {
      fontSize: 24,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '6px',
      borderWidth: '1.4px',
      borderColor: theme.palette.text.primary,
    },
    '&:focus': { bgcolor: focusedColor },
    '&:focus:hover': { bgcolor: hoverColor },
    '&.Mui-focusVisible': { backgroundColor: focusedColor },
  };
});