import React, { useEffect, useState } from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import { useSelectedClientApp } from "../../context/clientApplication/clientApplicationSelection.context";
import { clientConstants } from "../../../constants/global.constants";

const NavigationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { selectedClientApp } = useSelectedClientApp();
  const [isVesselTabEnabled, setIsVesselTabEnabled] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  useEffect(() => {
    const isMdfClientApp = selectedClientApp?.clientApp?.tags
      ?.toLowerCase()
      .includes(clientConstants.MDF_KEYWORD);

    setIsVesselTabEnabled(isMdfClientApp ?? false);

    // eslint-disable-next-line
  }, [selectedClientApp]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: theme.background.page,
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: theme.background.componentLight }}
        >
          <Tabs
            value={location.pathname}
            onChange={handleChange}
            sx={{ color: theme.palette.primary.light }}
          >
            <Tab
              sx={{
                margin: "5px",
                height: "80%",
                text: "bold",
                color: theme.palette.secondary.contrastText,
              }}
              label={t("navigation.general")}
              value={"/"}
            />
            <Tab
              label={t("navigation.vessels")}
              value="/vessel"
              id="VesselsTab"
              disabled={!isVesselTabEnabled}
              title={!isVesselTabEnabled ? t("navigation.vessel_functionality_is_not_available_for_this_client") : ""}
              sx={{
                ...(isVesselTabEnabled
                  ? {}
                  : {
                      pointerEvents: "auto!important",
                      backgroundColor: theme.background.page
                    }),
              }}
            />
            <Tab label={t("navigation.ports")} value="/port" id="PortsTab"/>
          </Tabs>
        </AppBar>
      </Box>
    </>
  );
};

export default NavigationComponent;
