import { BaseService } from "../BaseService";
import { headerKey, path } from "./vesselService.constants";
import { VesselProcessingAction } from "../../../shared/models/vessel/vesselProcessingAction.enum";
import { ResponseMessage } from "../../../shared/models/shared/error/errorResponse.model";
import { CRUDResponse } from "../../../shared/models/shared/CRUDResponse.model";
import i18n from "i18next";

export class VesselService extends BaseService {
  public async uploadVesselFile(
    file: File,
    tenantId: string,
    clientId: string,
    action: VesselProcessingAction = VesselProcessingAction.Unknown
  ): Promise<CRUDResponse<ResponseMessage>> {
    let crudResponse: CRUDResponse<ResponseMessage> = {
      success: [],
      errors: [],
    };

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("action", action.toString());

      let resposne = await this.post<ResponseMessage>(
        path.VESSEL_PERSIST_RAW_VESSEL_FILE,
        formData,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
            [headerKey.CC_TENANT_ID]: tenantId,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (this.isResponseSuccess(resposne)) {
        crudResponse.success.push(
          i18n.t("service.api.request_has_been_submited_successfully")
        );
      }
    } catch (error: any) {
      if (error.response) {
        let message: ResponseMessage | undefined =
          error?.response?.data?.errorResponse;

        if (message?.errors && Array.isArray(message.errors)) {
          crudResponse.errors = message.errors.map((obj) => obj.Message);
        } else {
          crudResponse.errors.push("An unknown error occurred.");
        }
      } else {
        crudResponse.errors.push(
          error?.message?.toString() || "An unknown error occurred."
        );
      }
    }

    return crudResponse;
  }
}
