import { Box, Button, Collapse, FormControlLabel, Stack, Switch } from "@mui/material";
import { theme } from "../../../styles/theme";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useFormContext, UseFormReturn, useFieldArray, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { UndoButton } from "../styled/button/styledUndoButton.component";
import { FormFields } from "../../../routes/general/general.route";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelectedCustomer } from "../../context/customer/customerSelection.Context";
import { ModuleNames } from "../../models/shared/module/ModuleNames";
import { StyledTextField } from "../styled/textfield/styledTextField.component";

const moduleName = ModuleNames.ERP;

interface IEnvironmentalMonitorModuleProps {
  handleUndo: (moduleName:string) => void;
  methods: UseFormReturn<FormFields>;
  disableUndo: boolean;
  handleDisable: (moduleName:ModuleNames, value:boolean) => void;
  handleChange: (moduleName:ModuleNames) => void;
}

const EnvironmentalMonitorModule = (props: IEnvironmentalMonitorModuleProps) => {
  const { t } = useTranslation();
  const { control, getValues, register, formState: { errors }, trigger, setValue } = useFormContext();

  const { selectedCustomer } = useSelectedCustomer();

  const erpSwitchState = useWatch({ control, name: "IsActiveErp" });

  const [open, setOpen] = useState(true);

  const handleExpandItems = () => {
    setOpen(!open);
  };

  const { fields, remove } = useFieldArray({
    control,
    name: "erpInformation.vesselAssignment"
  });

  const formValues = props.methods.getValues();
  
  const [contentChanged, setContentChanged] = useState(false);
  
  useEffect(() => {
    setContentChanged(false);
  }, [selectedCustomer, props.disableUndo]);

  useEffect(() => {
    fields.forEach((_, index) => {
      setValue(`erpInformation.vesselAssignment[${index}].wcn`, getValues(`erpInformation.vesselAssignment[${index}].wcn`), {
        shouldValidate: true,
      });
      setValue(`erpInformation.vesselAssignment[${index}].accessToken`, getValues(`erpInformation.vesselAssignment[${index}].accessToken`), {
        shouldValidate: true,
      });
      trigger([`erpInformation.vesselAssignment[${index}].wcn`, `erpInformation.vesselAssignment[${index}].accessToken`]);
    });
  }, [erpSwitchState, fields, setValue, trigger, getValues]);

  return (
    <Box
      key={"module_EnvironmentalMonitor"}
      sx={{
        backgroundColor: theme.background.componentLight,
        color: theme.palette.secondary.contrastText,
        padding: "1rem",
        borderRadius: "0.5rem",
        marginBottom: "1rem",
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box textAlign="left">
          <h1>{t("component.modules.environment_monitor")}:</h1>
        </Box>
        <Box
          textAlign="right"
          justifyContent={"flex-end"}
          alignItems="center"
          margin="auto"
        >
          <UndoButton
            id="UndoButtonERP"
            disabled={!contentChanged}
            onClick={() => {
              props.handleUndo(moduleName);
              setContentChanged(false);
              props.handleDisable(moduleName, false);
            }}
          />
        </Box>
      </Stack>
      <Box
        key={"adapter_AbsEmissionsReporter"}
        sx={{
          backgroundColor: theme.background.component,
          color: theme.palette.secondary.contrastText,
          borderRadius: "15px",
          marginBottom: "0.5rem",
        }}
      >
        <Box
          key={"adapter_wrapper_AbsEmissionsReporter"}
          sx={{
            backgroundColor: theme.background.componentLight,
          }}
        ></Box>

        <Box
          key={"toggle_wrrapper_AbsEmissionsReporter"}
          sx={{
            backgroundColor: theme.colors.darkBlue,
            borderRadius: "15px 15px 0 0",
            padding: "1rem",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Box>
              <Controller
                name="IsActiveErp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    key={"toggle_label_Veson"}
                    control={
                      <Switch
                        key={"IsActiveErp"}
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          setContentChanged(true)
                          props.handleDisable(moduleName, true);
                          props.handleChange(moduleName);
                        }}
                      />
                    }
                    label={t("routes.general.abs_emissions_reporter")}
                  />
                )}
              />
            </Box>
            <Box textAlign="right" alignItems="center" margin="auto">
              <Button
                onClick={() => {
                  setValue("erpInformation.vesselAssignment", [
                    ...(getValues().erpInformation.vesselAssignment || []),
                    {
                      wcn: "",
                      accessToken: "",
                    },
                  ]);
                  setContentChanged(true);
                  props.handleDisable(moduleName, true);
                }}
              >
                <AddIcon sx={{ fontSize: "2rem", mr: 1 }}></AddIcon>
              </Button>
              <Button onClick={handleExpandItems}>
                {open ? (
                  <ExpandLess
                    sx={{ fontSize: "2rem", mr: 1 }}
                  ></ExpandLess>
                ) : (
                  <ExpandMore sx={{ fontSize: "2rem", mr: 1 }}></ExpandMore>
                )}                
              </Button>
            </Box>
          </Stack>
        </Box>
        <br />
        <Collapse in={open} timeout="auto" unmountOnExit>
          {fields.map((item, k) => (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              key={"absEmissionsReporter.Stack" + k}
              justifyContent="space-between"
              sx={{ width: "100%", marginBottom: '8px' }}
            >
              <Box sx={{ width: "100%" }}>
                <StyledTextField
                  {...register(`erpInformation.vesselAssignment[${k}].wcn`, {
                    required: erpSwitchState ? true : false,
                  })}
                  value = {formValues.erpInformation.vesselAssignment[k]?.wcn}
                  id={"absEmissionsReporter.WCN"}
                  key={"absEmissionsReporter.WCN" + k}
                  label={t("routes.general.wcn")}
                  sx={{ width: "30%" }}
                  maxlength={50}
                  onChange={(e: any) => {
                    setValue(`erpInformation.vesselAssignment[${k}].wcn`, e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setContentChanged(true);
                    props.handleDisable(moduleName, true);
                    props.handleChange(moduleName);
                  }}
                  onBlur={() => {
                    trigger(`erpInformation.vesselAssignment[${k}].wcn`);
                  }}
                  error={Boolean((errors.erpInformation as any)?.vesselAssignment?.[k]?.wcn)}
                  helperText={
                    (errors.erpInformation as any)?.vesselAssignment?.[k]?.wcn &&
                    t("routes.general.required")
                  }
                  disabled={!erpSwitchState}
                />

                <StyledTextField
                  {...register(`erpInformation.vesselAssignment[${k}].accessToken`, {
                    required: erpSwitchState ? true : false,
                  })}
                  value = {formValues.erpInformation.vesselAssignment[k]?.accessToken}
                  id={"absEmissionsReporter.accessToken"}
                  key={"absEmissionsReporter.accessToken" + k}
                  label={t("routes.general.access_token")}
                  sx={{ width: "60%" }}
                  maxlength={50}
                  onChange={(e: any) => {
                    setValue(`erpInformation.vesselAssignment[${k}].accessToken`, e.target.value, {
                      shouldValidate: true,
                      shouldDirty: true
                    });
                    setContentChanged(true);
                    props.handleDisable(moduleName, true);
                    props.handleChange(moduleName);
                  }}
                  onBlur={() => {
                    trigger(`erpInformation.vesselAssignment[${k}].accessToken`);
                  }}
                  error={Boolean((errors.erpInformation as any)?.vesselAssignment?.[k]?.accessToken)}
                  helperText={
                    (errors.erpInformation as any)?.vesselAssignment?.[k]?.accessToken &&
                    t("routes.general.required")
                  }
                  disabled={!erpSwitchState}
                />
                <Button
                  sx={{ width: "10%", marginTop: "25px" }}
                  onClick={() => {
                    if (fields.length > 1) {
                      remove(k);
                      setContentChanged(true);
                      props.handleDisable(moduleName, true);
                      props.handleChange(moduleName);
                    }
                  }}
                >
                  <DeleteIcon
                    key={"module_delete_EnvironmentalMonitor_item_" + { k }}
                    sx={{ fontSize: "2rem", mr: 2 }}
                  />
                </Button>
              </Box>
            </Stack>
          ))}
        </Collapse>
      </Box>{" "}
      {/* adapter_AbsEmissionsReporter */}
    </Box>
  );
};

export default EnvironmentalMonitorModule;