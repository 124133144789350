import { Box, CssBaseline } from '@mui/material';
import { SignInButton } from './components/SignInButton';
import logo from '../../assets/logo.png';
import { ROUTES } from '../../router/router';
import { theme } from '../../styles/theme';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function Login() {
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated) {
			navigate(ROUTES.general.path);
		}
	}, [isAuthenticated, navigate]);

	return (
		<>
			<CssBaseline />
			<Box
				data-testid={'login-route'}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					alignItems: 'center',
					justifyContent: 'center',
					backgroundColor: theme.background.page,
					color: theme.palette.secondary.contrastText,
				}}
			>
				<Box component="img" alt="ABS Logo" src={logo} />
				<SignInButton />
			</Box>
		</>
	);
}
