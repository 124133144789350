import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/system/Box";
import { theme } from "../../../styles/theme";

export interface ILoadingComponentProps {
  height: string;
  backgroundColor?: string;
  marginTop?: string;
}

export function LoadingComponent(props: ILoadingComponentProps) {
  const STYLE_PROPS = {
    height: props.height,
    backgroundColor: props.backgroundColor || theme.background.componentLight,
    width: "100%",
    margin: 0,
    marginTop: props.marginTop || 3,
  };

  return (
    <Box sx={STYLE_PROPS} data-testid="loading-component">
      <Skeleton
        sx={{
          height: "100%",
          width: "100%",
        }}
        variant="rectangular"
        animation="wave"
      ></Skeleton>
    </Box>
  );
}
