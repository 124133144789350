import { Customer } from "../../../shared/models/customer/customer.model";
import { Partners } from "../../../shared/models/shared/partner/partners.model";
import { CRUDResponse } from "../../../shared/models/shared/CRUDResponse.model";
import { ResponseMessage } from "../../../shared/models/shared/error/errorResponse.model";
import { Tenant } from "../../../shared/models/shared/tenant/tenant.model";
import { TenantResponse } from "../../../shared/models/shared/tenant/tenantResponse.model";
import { BaseService } from "../BaseService";
import { headerKey, paging, path } from "./customerService.constants";
import { FormFields } from "../../../routes/general/general.route";
import { PartnersRegister } from "../../../shared/models/shared/partner/partnersRegister.model";
import { VesonRegisterPartner } from "../../../shared/models/shared/partner/vesonRegisterPartner.model";
import { EcdisRegisterPartner } from "../../../shared/models/shared/partner/ecdisRegisterPartner.model";
import { VesonInformation } from "../../../shared/models/shared/partner/vesonInformation.model";
import { EcdisInformation } from "../../../shared/models/shared/partner/ecdisInformation.model";
import { PartnersUpdate } from "../../../shared/models/shared/partner/partnersUpdate.model";
import { TenantUpdate } from "../../../shared/models/shared/tenant/tenantUpdate.model";
import { VesselUpdate } from "../../../shared/models/shared/vessel/vesselUpdate.model";
import { ISelectedCustomer } from "../../../shared/context/customer/selectedCustomer.context.model";
import { ERPRegisterPartner } from "../../../shared/models/shared/partner/erpRegisterPartner.model";
import i18n from "i18next";
import { ErpInformation } from "../../../shared/models/shared/partner/erpInformation.model";
import { PartnerName } from "./partnerName";

export class CustomerService extends BaseService {
  private page_num: number = 0;

  public async getCustomersByClientAsync(
    clientId: string | null
  ): Promise<Customer[]> {
    let filteredCustomers: Customer[] = [];
    let responseData = null;

    do {
      responseData = await this.get<TenantResponse>(
        path.TENANT_ADAPTERS_GET_TENANTS,
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
          },
          params: {
            page_num: this.page_num,
            page_size: paging.MAX_PAGE_SIZE,
          },
        }
      );

      let tenants = responseData?.data?.tenants as Tenant[];

      tenants?.forEach((item) => {
        const customer: Customer = {
          id: item.ccTenantId,
          name: item.tenantName,
          client_tenant_id: item.clientTenantId,
          clientId: 0, // Add the clientId property
          commonCoreCloudId: "", // Add the commonCoreCloudId property
          partners: {} as Partners,
        };
        filteredCustomers.push(customer);
      });

      this.page_num++;
    } while (this.CustomerNextPageExists(responseData?.data));

    this.resetPageNum();

    return filteredCustomers;
  }

  public async getCustomerByClientIdAsync(
    tenantId: string | null,
    clientId: string | null
  ): Promise<Tenant> {
    return (await this.get<TenantResponse>(path.TENANT_ADAPTERS_GET_TENANTS, {
      headers: {
        [headerKey.CC_ACTIVE_CLIENT]: clientId,
        [headerKey.CC_TENANT_ID]: `["${tenantId}"]`,
      },
      params: {
        page_num: this.page_num,
        page_size: paging.MAX_PAGE_SIZE,
      },
    }).then((response) => {
      this.resetPageNum();
      return response.data.tenants[0];
    })) as Tenant;
  }

  private resetPageNum(): void {
    this.page_num = 0; // Reset page_num to 0
  }

  public async updateCustomerAsync(
    clientId: string | null,
    customerId: string,
    data: FormFields,
    selectedCustomer: ISelectedCustomer
  ): Promise<CRUDResponse> {
    let crudResponse: CRUDResponse = {
      success: [],
      errors: [],
    };

    let originalData: Tenant = await this.getCustomerByClientIdAsync(
      customerId,
      clientId
    );

    if (data.IsActiveVeson) {
      let response: CRUDResponse = await this.verifyWithPartnerAsync(
        data,
        customerId,
        clientId,
        PartnerName.Veson
      );

      crudResponse.errors.push(...response.errors);
    }

    if (data.IsActiveEcdis) {
      let response: CRUDResponse = await this.verifyWithPartnerAsync(
        data,
        customerId,
        clientId,
        PartnerName.Ecdis
      );

      crudResponse.errors.push(...response.errors);
    }

    if (data.IsActiveErp) {
      let response: CRUDResponse = await this.verifyWithPartnerAsync(
        data,
        customerId,
        clientId,
        PartnerName.Erp
      );

      crudResponse.errors.push(...response.errors);
    }

    if (
      crudResponse.errors.length === 0 &&
      data.IsActiveVeson &&
      selectedCustomer.partners.vesonInformation == null
    ) {
      // Register with Veson
      let responseRegiserWithPartner = await this.registerWithPartnerAsync(
        data,
        originalData.ccTenantId,
        originalData.clientTenantId,
        clientId,
        PartnerName.Veson
      );

      crudResponse.errors.push(...responseRegiserWithPartner.errors);
    }

    if (
      crudResponse.errors.length === 0 &&
      data.IsActiveEcdis &&
      selectedCustomer.partners.ecdisInformation == null
    ) {
      // Register with Ecdis
      let responseRegiserWithPartner = await this.registerWithPartnerAsync(
        data,
        originalData.ccTenantId,
        originalData.clientTenantId,
        clientId,
        PartnerName.Ecdis
      );

      crudResponse.errors.push(...responseRegiserWithPartner.errors);
    }

    if (
      crudResponse.errors.length === 0 &&
      data.IsActiveErp &&
      selectedCustomer.partners.erpInformation == null
    ) {
      // Register with ERP
      let responseRegiserWithPartner = await this.registerWithPartnerAsync(
        data,
        originalData.ccTenantId,
        originalData.clientTenantId,
        clientId,
        PartnerName.Erp
      );

      crudResponse.errors.push(...responseRegiserWithPartner.errors);
    }

    if (crudResponse.errors.length === 0) {
      originalData = await this.getCustomerByClientIdAsync(
        customerId,
        clientId
      );

      //Update customer data
      let tenantUpdate: TenantUpdate = {} as TenantUpdate;
      tenantUpdate.ccTenantId = originalData.ccTenantId;
      tenantUpdate.clientTenantId = originalData.clientTenantId;
      tenantUpdate.tenantName = data.clientName;

      //Updating partners
      if (data.IsActiveVeson || data.IsActiveEcdis || data.IsActiveErp) {
        let partners: PartnersUpdate = {} as PartnersUpdate;

        if (data.IsActiveVeson) {
          let vesonInformation: VesonInformation = {} as VesonInformation;
          vesonInformation.vesonInternalId =
            data.vesonInformation.vesonInternalId;
          vesonInformation.vesonToken = data.vesonInformation.vesonToken;

          partners.vesonInformation = vesonInformation;
        }

        if (data.IsActiveEcdis) {
          let ecdisInformation: EcdisInformation = {} as EcdisInformation;
          ecdisInformation.createResources = true;
          ecdisInformation.connectionString =
            originalData.partners.ecdisInformation?.connectionString ?? "";

          partners.ecdisInformation = ecdisInformation;
        }

        if (data.IsActiveErp) {
          let erpInformation: ErpInformation = {} as ErpInformation;
          erpInformation.vesselAssignment =
            data.erpInformation.vesselAssignment;

          partners.erpInformation = erpInformation;
        }

        tenantUpdate.partners = partners;
      }

      //Preserving original vessels data
      if (originalData.vessels != null && originalData.vessels.length > 0) {
        tenantUpdate.vessels = originalData.vessels.map((vessel) => {
          let vesselUpdate: VesselUpdate = {
            imo: vessel.imo,
            type: vessel.type,
            name: vessel.name,
            deadweight: vessel.deadweight,
          };

          return vesselUpdate;
        });
      }

      //Save customer data
      try {
        let response = await this.put<CRUDResponse>(
          path.TENANT_ADAPTERS_UPDATE_TENANT,
          tenantUpdate,
          {
            headers: {
              [headerKey.CC_ACTIVE_CLIENT]: clientId,
            },
          }
        );
        if (response.status === 200) {
          crudResponse.success.push(
            i18n.t("service.api.request_has_been_submited_successfully")
          );
        }
      } catch (error: any) {
        if (error.response) {
          let errorResponse: ResponseMessage = error?.response?.data;
          crudResponse.errors = errorResponse.errors.map(
            (error) => error.Message
          );
        } else {
          crudResponse.errors.push(error?.message.toString());
        }
      }
    }

    return crudResponse;
  }

  public async createNewCustomerAndRegisterWithPartnersAsync(
    clientId: string | null,
    data: FormFields
  ): Promise<CRUDResponse> {
    let response: any;

    let crudResponse: CRUDResponse = {
      success: [],
      errors: [],
    };

    try {
      response = await this.post<TenantResponse | ResponseMessage | null>(
        path.TENANT_ADAPTERS_CREATE_NEW_TENANT,
        {
          clientTenantId: data.client_id,
          tenantName: data.clientName,
          vessels: [],
        },
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
          },
        }
      );
      crudResponse.data = response?.data;
    } catch (error: any) {
      if (error.response) {
        let errorResponse: ResponseMessage = error?.response?.data;
        crudResponse.errors = errorResponse.errors.map(
          (error) => error.Message
        );
      } else {
        crudResponse.errors.push(error?.message.toString());
      }
    }

    if (crudResponse.errors.length === 0) {
      crudResponse.success.push(
        i18n.t("service.api.request_has_been_submited_successfully")
      );

      let tenantResponse: TenantResponse;

      tenantResponse = response?.data as TenantResponse;

      if (data.IsActiveVeson) {
        let responseRegiserWithPartner = await this.registerWithPartnerAsync(
          data,
          tenantResponse?.ccTenantId,
          tenantResponse?.clientTenantId,
          clientId,
          PartnerName.Veson
        );

        crudResponse.errors.push(...responseRegiserWithPartner.errors);
      }

      if (data.IsActiveEcdis) {
        let responseRegiserWithPartner = await this.registerWithPartnerAsync(
          data,
          tenantResponse?.ccTenantId,
          tenantResponse?.clientTenantId,
          clientId,
          PartnerName.Ecdis
        );

        crudResponse.errors.push(...responseRegiserWithPartner.errors);
      }

      if (data.IsActiveErp) {
        let responseRegiserWithPartner = await this.registerWithPartnerAsync(
          data,
          tenantResponse?.ccTenantId,
          tenantResponse?.clientTenantId,
          clientId,
          PartnerName.Erp
        );

        crudResponse.errors.push(...responseRegiserWithPartner.errors);
      }
    }

    return crudResponse;
  }

  private async registerWithPartnerAsync(
    data: FormFields,
    ccTenantId: string,
    clientTenantId: string,
    clientId: string | null,
    registerWithPartner: PartnerName
  ): Promise<CRUDResponse> {
    let crudResponse: CRUDResponse = {
      success: [],
      errors: [],
    };

    let partners: PartnersRegister = {} as PartnersRegister;

    if (registerWithPartner === PartnerName.Veson) {
      const vesonRegisterPartner: VesonRegisterPartner = {
        vesonInternalId: data.vesonInformation.vesonInternalId,
        vesonToken: data.vesonInformation.vesonToken,
      } as VesonRegisterPartner;

      partners.vesonInformation = vesonRegisterPartner;
    }

    if (registerWithPartner === PartnerName.Ecdis) {
      const ecdisRegisterPartner: EcdisRegisterPartner = {
        createResources: true,
      } as EcdisRegisterPartner;

      partners.ecdisInformation = ecdisRegisterPartner;
    }

    if (registerWithPartner === PartnerName.Erp) {
      const erpRegisterPartner: ERPRegisterPartner = {
        vesselAssignment: data.erpInformation.vesselAssignment,
      } as ERPRegisterPartner;

      partners.erpInformation = erpRegisterPartner;
    }

    let response: any;

    try {
      response = await this.post<TenantResponse>(
        path.TENANT_ADAPTERS_REGISTER_WITH_PARTNER,
        {
          ccTenantId: ccTenantId,
          clientTenantId: clientTenantId,
          partners: partners,
        },
        {
          headers: {
            [headerKey.CC_ACTIVE_CLIENT]: clientId,
          },
        }
      );

      if (response.status === 201) {
        crudResponse.success.push(
          i18n.t("service.customer.partner") +
            " " +
            registerWithPartner.toUpperCase() +
            " " +
            i18n.t("service.customer.created_successfully")
        );
      }
    } catch (error: any) {
      if (error.response) {
        let errorResponse: ResponseMessage = error?.response?.data;
        crudResponse.errors = errorResponse.errors.map(
          (error) =>
            i18n.t("service.customer.partner") +
            " " +
            registerWithPartner.toUpperCase() +
            ": " +
            error.Message
        );
      } else {
        crudResponse.errors.push(error?.message.toString());
      }
    }

    return crudResponse;
  }

  private async verifyWithPartnerAsync(
    data: FormFields,
    ccTenantId: string,
    clientId: string | null,
    verifyPartner: PartnerName
  ): Promise<CRUDResponse> {
    let crudResponse: CRUDResponse = {
      success: [],
      errors: [],
    };

    let partners: PartnersRegister = {} as PartnersRegister;

    if (verifyPartner === PartnerName.Veson) {
      const vesonRegisterPartner: VesonRegisterPartner = {
        vesonInternalId: data.vesonInformation.vesonInternalId,
        vesonToken: data.vesonInformation.vesonToken,
      } as VesonRegisterPartner;

      partners.vesonInformation = vesonRegisterPartner;
    }

    if (verifyPartner === PartnerName.Ecdis) {
      const ecdisRegisterPartner: EcdisRegisterPartner = {
        createResources: true,
      } as EcdisRegisterPartner;

      partners.ecdisInformation = ecdisRegisterPartner;
    }

    if (verifyPartner === PartnerName.Erp) {
      const erpRegisterPartner: ERPRegisterPartner = {
        vesselAssignment: data.erpInformation.vesselAssignment,
      } as ERPRegisterPartner;

      partners.erpInformation = erpRegisterPartner;
    }

    let response: any;

    let partnerData:
      | VesonRegisterPartner
      | EcdisRegisterPartner
      | ERPRegisterPartner
      | null = null;
    let partnerPath: string = "";

    if (verifyPartner === PartnerName.Veson) {
      partnerPath = path.TENANT_ADAPTERS_VALIDATE_PARTNERS_VESON;
      partnerData = partners.vesonInformation;
    } else if (verifyPartner === PartnerName.Ecdis) {
      partnerPath = path.TENANT_ADAPTERS_VALIDATE_PARTNERS_ECDIS;
      partnerData = partners.ecdisInformation;
    } else if (verifyPartner === PartnerName.Erp) {
      partnerPath = path.TENANT_ADAPTERS_VALIDATE_PARTNERS_ERP;
      partnerData = partners.erpInformation;
    }

    try {
      response = await this.post<TenantResponse>(partnerPath, partnerData, {
        headers: {
          [headerKey.CC_ACTIVE_CLIENT]: clientId,
          [headerKey.CC_TENANT_ID]: ccTenantId,
        },
      });

      if (response.status === 200) {
        crudResponse.errors = response.data.errors.map(
          (error: any) => error.message
        );
      }
    } catch (error: any) {
      if (error.response) {
        let errorResponse: ResponseMessage = error?.response?.data;
        crudResponse.errors = errorResponse.errors.map(
          (error) =>
            i18n.t("service.customer.partner") +
            " " +
            verifyPartner.toUpperCase() +
            ": " +
            error.Message
        );
      } else {
        crudResponse.errors.push(error?.message.toString());
      }
    }

    return crudResponse;
  }

  private CustomerNextPageExists(responseData: TenantResponse): boolean {
    return (
      responseData?.paging?.totalCount / responseData?.paging?.pageSize <
      responseData?.paging?.pageNumber
    );
  }
}
