import { createContext, ReactNode, useContext, useState } from "react";
import { IGlobalProgressBarItem } from "./globalProgressBar.Context.model";

interface GlobalProgressBarContextType {
  addProgressItem: () => void;
  removeProgressItem: () => void;
  isProgressBarActive: () => boolean;
}

export const GlobalProgressBarContext = createContext<
  GlobalProgressBarContextType | undefined
>(undefined);

export const useGlobalProgressBarService = () => {
  const context = useContext(GlobalProgressBarContext);
  if (context === undefined) {
    throw new Error(
      "useGlobalProgressBarService must be used within a GlobalProgressBarServiceProvider"
    );
  }
  return context;
};

export const GlobalProgressBarProvider = ({ children }: { children: ReactNode }) => {
  const [progressItems, setProgressItems] = useState<IGlobalProgressBarItem[]>([]);

  const addProgressItem = () => {
    const id = Date.now();
    setProgressItems([...progressItems, { id }]);
  };

  const removeProgressItem = () => {
    setProgressItems((prevItems) => prevItems.slice(1));
  };

  const isProgressBarActive = () => progressItems?.length > 0 || false;

  return (
    <GlobalProgressBarContext.Provider value={{ addProgressItem, removeProgressItem, isProgressBarActive }}>
      {children}
    </GlobalProgressBarContext.Provider>
  );
};