import { Divider, Typography } from "@mui/material";

interface StyledHeaderProps {
  label: string;
}

export const StyledHeader: React.FC<StyledHeaderProps> = ({ label }) => {
  return (
    <>
      <Typography variant="h2" component="h2">
        {label}
      </Typography>
      <Divider textAlign="right" sx={{ mb: 2 }}></Divider>
    </>
  );
};
