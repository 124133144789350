export interface AppConfig {
    MSAL_CONFIG_CLIENT_ID: string;
    MSAL_CONFIG_TENANT_ID: string;
    MSAL_CONFIG_AUTHORITY: string;
    MSAL_REDIRECT_URI: string;
    BASE_URL: string;
  }
  
  export let appConfig: AppConfig = {
    MSAL_CONFIG_CLIENT_ID: '',
    MSAL_CONFIG_TENANT_ID: '',
    MSAL_CONFIG_AUTHORITY: '',
    MSAL_REDIRECT_URI: '',
    BASE_URL: '',
  } as AppConfig;  
  
  export const loadConfig = async (): Promise<AppConfig> => {
     
    if (appConfig.MSAL_CONFIG_CLIENT_ID !== '') {
      return appConfig; 
    }
  
    const response = await fetch('/config.json');
    if (!response.ok) {
      throw new Error('Failed to load config.json');
    }
  
    const config = await response.json() as AppConfig;
    appConfig = config; 
    return config;
  };    
  