import { IContext } from "./app.context.model";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

export const useAppContext = (): IContext => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();

  return {
    isAuthenticated: isAuthenticated,
    user: {
      name: accounts[0]?.name,
      tenantId: accounts[0]?.tenantId,
    },
  };
};
