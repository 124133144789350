import { localStorageConst } from "../../constants/global.constants";

export class ClientUtility {
  public static GetSelectedClientId(): string | null {
    let savedClient = localStorage.getItem(
      localStorageConst.SELECTED_CLIENT_APP
    );
    return savedClient ? JSON.parse(savedClient).id : null;
  }

  public static IsSelectedClientApp(clientId: string): boolean {
    return ClientUtility.GetSelectedClientId() === clientId;
  }
}
