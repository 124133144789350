import React from "react";
import { 
  Box
} from "@mui/material";
import { theme } from "../../../../styles/theme";
import { PortFunctionsColumn } from "./portFunctionsColumn.component";
import { Function } from "../../../models/port/protoPort.model";

interface ComponentProperties {
  portFunctions: Function[];
  selectedPortFunctions: Function[];
  isUnecePortAlias: boolean;
}

export const PortFunctions: React.FC<ComponentProperties> = ({ portFunctions, selectedPortFunctions, isUnecePortAlias }) => {
  const itemsPerColumn = (portFunctions.length / 2) + (portFunctions.length % 2);

  let portFunctionsColumns = [];
  let startIndex = 0;
  let endIndex = itemsPerColumn;
  let componentIndex = 0;
  do {
    portFunctionsColumns.push(<PortFunctionsColumn
        key={`port-func-column-${componentIndex}`}
        portFunctions={portFunctions.slice(startIndex, endIndex)}
        selectedPortFunctions={selectedPortFunctions}
        isUnecePortAlias={isUnecePortAlias} />);
    startIndex += itemsPerColumn;
    endIndex += itemsPerColumn;
    componentIndex++;
  } while(startIndex < portFunctions.length);

  return (
    <Box sx={{
        display: "flex",
        flexDirection: "row",
        width: "auto",
        backgroundColor: isUnecePortAlias ? theme.background.componentLight : theme.background.page,
        "& .Mui-disabled": {
            WebkitTextFillColor: theme.palette.text.primary
        }
    }}>
      {portFunctionsColumns}
    </Box>
  );
};