import { useMsal } from "@azure/msal-react";
import { useTheme } from "@mui/material";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export const SignOutButton = () => {
  const { instance } = useMsal();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleLogout = () => {
    const logoutOld = Number(localStorage.getItem('onboardingTool.logout'));
    localStorage.setItem('onboardingTool.logout', (logoutOld + 1).toString());
    instance.logoutRedirect();
  };

  return (
    <IconButton
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={() => handleLogout()}
      color="inherit"
      sx={{ p: 0 }}
      id="SignOutButton"
    >
      <LogoutIcon sx={{ mr: 1, color: theme.palette.text.disabled }} />
      <Typography variant="menuItem" component="div" noWrap={true}>
        {t("routes.login.components.sign_out")}
      </Typography>
    </IconButton>
  );
};
