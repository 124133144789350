import { SignalRNegotiate } from "../../../shared/models/shared/signalR/signalRNegotiate.model";
import { BaseService } from "../BaseService";
import { paths } from "./signalRService.constants";

export class SignalRService extends BaseService {
  public async getUrlAndAccessToken(): Promise<SignalRNegotiate> {
    let response: any;

    let signalRNegotiate: SignalRNegotiate = {
      URL: "",
      AccessToken: ""
    }

    try {
      response = await this.post<SignalRNegotiate>(paths.NEGOTIATE, {});

      signalRNegotiate.URL = response.data.url;
      signalRNegotiate.AccessToken = response.data.accessToken;
    } catch (error) {
      console.error("Failed to fetch URL and AccessToken from Negotiate:", error);
    }

    return signalRNegotiate;
  }
}