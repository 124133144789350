import { Box, Stack, ThemeProvider } from "@mui/material";
import { theme } from "../../../../styles/theme";
import { useTranslation } from "react-i18next";
import { StyledTextField } from "../../styled/textfield/styledTextField.component";
import { StyledBox } from "../../styled/box/styledBox.component";
import { StyledHeader } from "../../styled/header/styledHeader.component";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { UndoButton } from "../../styled/button/styledUndoButton.component";
import { useSelectedCustomer } from "../../../context/customer/customerSelection.Context";
import { ModuleNames } from "../../../models/shared/module/ModuleNames";
import { useSelectedClientApp } from "../../../context/clientApplication/clientApplicationSelection.context";

const moduleName = ModuleNames.Customer;

interface IAddNewCustomerProps {
  handleUndo: (moduleName:string) => void;
  disableUndo: boolean;
  handleDisable: (moduleName:ModuleNames, value:boolean) => void;
  handleChange: (moduleName:ModuleNames) => void;
}

export const AddNewCustomer = (props: IAddNewCustomerProps) => {
  const { t } = useTranslation();
  const {
    getValues,
    register,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext();

  const { selectedClientApp } = useSelectedClientApp();

  const { selectedCustomer } = useSelectedCustomer();

  const [contentChanged, setContentChanged] = useState(false);

  const clientNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setContentChanged(false);
  }, [selectedCustomer, props.disableUndo]);

  const clientName = watch("clientName", "");

  useEffect(() => {
    setValue("clientName", getValues("clientName"), {
      shouldValidate: true,
    });
    trigger(["clientName", "client_id"]);
  }, [setValue, getValues, trigger, clientName]);

  // Focus on clientName input on component mount
  useEffect(() => {
    if (clientNameRef.current) {
      clientNameRef.current.focus();
    }
  }, [selectedClientApp, selectedCustomer]);

  return (
    <Box>
      <ThemeProvider theme={theme}>
        <StyledHeader label={t("component.customer.customer_details")} />
        <StyledBox
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Stack
            direction="row"
            sx={{ width: "100%" }}
            justifyContent="space-between"
          >
            <Box textAlign="left" sx={{ width: "100%" }}>
              <StyledTextField
                {...register("clientName", {
                  required: true,
                })}
                id={"component.customer.name"}
                key={"component.customer.name"}
                label={t("component.customer.name")}
                sx={{ width: "33%" }}
                maxlength={50}
                inputRef={clientNameRef}
                onBlur={() => {
                  trigger("clientName");
                }}
                onChange={(e: any) => {
                  setValue("clientName", e.target.value, {
                    shouldValidate: true,
                  });
                  setContentChanged(true);
                  props.handleDisable(moduleName, true);
                  props.handleChange(moduleName);
                }}
                error={Boolean(errors.clientName)}
                helperText={
                  errors.clientName && t("component.customer.field_is_required")
                }
              />

              <StyledTextField
                {...register("client_id")}
                label={t("component.customer.client_id")}
                disabled={true}
                maxlength={50}
                sx={{ width: "33%" }}
              />

              <StyledTextField
                {...register("common_core_cloud_id")}
                label={t("component.customer.common_core_cloud_id")}
                disabled={true}
                maxlength={50}
                sx={{ width: "33%" }}
              />
            </Box>
            <Box
              textAlign="right"
              justifyContent={"flex-end"}
              alignItems="center"
              margin="auto"
            >
              <UndoButton
                id="UndoButtonCustomer"
                disabled={!contentChanged}
                onClick={() => {
                  props.handleUndo(moduleName);
                  setContentChanged(false);
                  props.handleDisable(moduleName, false);
                }}
              />
            </Box>
          </Stack>
        </StyledBox>
      </ThemeProvider>
    </Box>
  );
};