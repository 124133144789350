import {useState} from "react";
import {Box} from "@mui/material";
import PortHeader from "../../shared/components/port/grid/portHeader.component";
import DataGridPort from "../../shared/components/port/grid/dataGridPort.component";
import PortDetailsModal from "../../shared/components/port/item/portDetailsModal.component";
import {CustomPort} from "../../shared/models/port/protoPort.model";

export function PortRoute() {
    const [initDataReload, setInitDataReload] = useState(false);
    const [openPortDetails, setOpenPortDetails] = useState(false);
    const [editCustomPort, setEditCustomPort] = useState<CustomPort | null>(null);
    const portCreatedHandler = (isPortCreated: boolean) => {
        if (isPortCreated) {
            setInitDataReload(true);
        }
    };
    const customPortsDataReloadedHandler = (isDataReloaded: boolean) => {
        if (isDataReloaded) {
            setInitDataReload(false);
        }
    };
    const portDetailsModalCloseHandler = () => {
        setOpenPortDetails(false);
        setEditCustomPort(null);
    };
    const addPortButtonClickedHandler = () => {
        setOpenPortDetails(true);
        setEditCustomPort(null);
    };
    const editClickHandler= (customPort: CustomPort) => {
        setOpenPortDetails(true);
        setEditCustomPort(customPort);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%%",
            }}
        >
            <PortHeader
                key="customer_ports"
                onAddPortButtonClickedHandler={addPortButtonClickedHandler} />
            <DataGridPort
                initDataReload={initDataReload}
                onDataReloaded={customPortsDataReloadedHandler}
                onEditClickHandler={editClickHandler} />
            <PortDetailsModal
                open={openPortDetails}
                onClose={portDetailsModalCloseHandler}
                onPortCreatedHandler={portCreatedHandler}
                editCustomPort={editCustomPort}
            />
        </Box>
    );
}