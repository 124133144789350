"use client";
import { LicenseInfo } from "@mui/x-license";

const licenseKey = process.env.REACT_APP_MUI_LICENCE_KEY;

if (licenseKey) {
  console.log("MUI License Key provided.");
} else {
  console.warn("MUI License Key not provided.");
}

LicenseInfo.setLicenseKey(licenseKey ?? "");

export default function MuiXLicense() {
  return null;
}
